import React, {useMemo, useState} from "react";
import IdentIcon from "@components/common/IndetIcon";
import {getEllipsisTxt} from "@helpers/formatters";
import qrCode from "@assets/icons/qr-code.svg";
import swap from "@assets/icons/swap.svg";
import plane from "@assets/icons/plane.svg";
import FormattedNumber from "@components/common/FormattedNumber";
import TabsCard from "@components/common/TabsCard";
import ListItem from "@pages/Wallet/ListItem";
import History from "@pages/Wallet/components/History";
import {useNavigate} from "react-router-dom";
import {ChainId, PageRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import BadgeIcon from "@components/common/BadgeIcon";
import Preloader from "@components/common/Preloader";
import CopyButton from "@components/common/CopyButton";
import {chainLogo, chainName} from "@helpers/chains";
import arrow from "@assets/icons/arrow-down.svg";
import NetworkSelection from "@components/NetworkSelection";

const Wallet = observer(() => {
  const { accountStore } = useStores();
  const { address, portfolio, assets, assetsLoaded, transactions, transactionsLoaded } = accountStore;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Assets');
  const [chain, setChain] = useState(ChainId.ALL);

  const filteredAssets = useMemo(() => {
    return chain === ChainId.ALL ? assets : assets.filter((asset) => asset.assetChain === chain);
  }, [assets, chain]);

  return (
    <div className="full-page d-flex flex-column">
      <div className="py-3 d-flex justify-content-between align-items-center">
        <CopyButton text={address} className="text-decoration-none wd-40 ht-40 rounded-circle overflow-hidden cur-pointer" noIcon>
          <IdentIcon string={address} className="identicon rounded-circle" size={40} />
        </CopyButton>
        <div className="ms-2 me-auto d-flex flex-column justify-content-center">
          <CopyButton text={address} className="tx-left text-decoration-none cur-pointer tx-17 lh-3" noIcon>
            {getEllipsisTxt(address, 4, '0x')}
          </CopyButton>
          <NetworkSelection network={chain} onChange={setChain} allNetworks>
            <div className="d-flex align-items-center tx-12">
              <img src={chainLogo(chain)} alt="chain" width={16}/>
              <span className="mx-1">{chainName(chain)}</span>
              <img src={arrow} alt="Choose network" width={16}/>
            </div>
          </NetworkSelection>
        </div>
        {/*<SideNav address={address} balance={portfolio?.totalBalance || 0} />*/}
      </div>

      <CopyButton className="my-5 d-flex flex-column align-items-center justify-content-center tx-center cur-pointer" text={address} noIcon>
        <FormattedNumber
          value={portfolio?.totalBalance}
          decimals={2}
          subZeros
          className="tx-34 tx-semibold"
          suffix="$"
          floor
        />
        {portfolio?.totalChange1d !== undefined && (
          <div className={!portfolio?.totalChange1d || portfolio?.totalChange1d === 0 ? 'tx-muted' : portfolio?.totalChange1d > 0 ? 'tx-success' : 'tx-danger'}>
            <FormattedNumber
              value={portfolio?.totalChange1d}
              decimals={2}
              className="tx-15 me-1"
              suffix="$"
              withSign
              floor
            />
            <FormattedNumber
              value={portfolio?.totalChangePercent1d}
              decimals={2}
              className="tx-15"
              suffix="("
              postfix="%)"
              floor
            />
          </div>
        )}
      </CopyButton>

      <div className="d-flex justify-content-between align-items-center gap-2">
        <button
          className="btn btn-secondary tx-13 wd-100p px-0 d-flex align-items-center py-2 justify-content-center"
          onClick={() => navigate(PageRoutes.RECEIVE)}
        >
          <img src={qrCode} alt="QR-Code" width={16} className="me-2 py-1" />
          <span>Receive</span>
        </button>
        <button
          className="btn btn-secondary tx-13 wd-100p px-0 d-flex align-items-center py-2 justify-content-center"
          onClick={() => navigate(PageRoutes.SWAP)}
        >
          <img src={swap} alt="Swap" width={16} className="me-2 py-1" />
          <span>Swap</span>
        </button>
        <button
          className="btn btn-secondary tx-13 wd-100p px-0 d-flex align-items-center py-2 justify-content-center"
          onClick={() => navigate(PageRoutes.SEND)}
        >
          <img src={plane} alt="Send" width={16} className="me-2 py-1" />
          <span>Send</span>
        </button>
      </div>

      <div className="row flex-1 mt-4">
        <div className="card ht-100p d-flex flex-column" id="assets-card">
          <TabsCard
            list={[{value: 'Assets'}, {value: 'History'}]}
            active={activeTab}
            onClick={setActiveTab}
          >
            <div>
              <div className="tab-element tab-Assets">
                {!assetsLoaded && (
                  <Preloader
                    className="d-flex flex-column"
                    iconSize={65}
                    textClass="mt-3"
                    inline
                  />
                )}
                {assetsLoaded && assets.map((asset, index) => (
                  <ListItem
                    asset={asset}
                    hide={chain !== ChainId.ALL && asset.assetChain !== chain}
                    key={`token-list-item-${asset.assetId}-${index}`}
                  />
                ))}
                {assetsLoaded && !assets.length && (
                  <div className="tx-center mt-3">
                    <BadgeIcon className="tx-40" badgeSize={85}>👜</BadgeIcon>
                    <div className="tx-28 tx-semibold my-2">
                      Portfolio is empty
                    </div>
                    <div className="tx-17 tx-muted">
                      Here, you can monitor the growth of your investments
                    </div>
                  </div>
                )}
                {assetsLoaded && !!assets.length && !filteredAssets.length && (
                  <div className="tx-center mt-3">
                    <BadgeIcon className="tx-40" badgeSize={85}>👜</BadgeIcon>
                    <div className="tx-28 tx-semibold my-2">
                      No assets on this network
                    </div>
                    <div className="tx-17 tx-muted">
                      Try switching to another network
                    </div>
                  </div>
                )}
              </div>
              <div className="tab-element tab-History">
                {!transactionsLoaded && (
                  <Preloader
                    className="d-flex flex-column"
                    iconSize={65}
                    textClass="mt-3"
                    inline
                  />
                )}
                {transactionsLoaded && !!transactions.length && <History />}
                {transactionsLoaded && !transactions.length && (
                  <div className="tx-center mt-3">
                    <BadgeIcon className="tx-40" badgeSize={85}>😢</BadgeIcon>
                    <div className="tx-28 tx-semibold my-2">
                      History is empty
                    </div>
                    <div className="tx-17 tx-muted">
                      Your transaction history will be displayed here
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TabsCard>
        </div>
      </div>
    </div>
  );
});

export default Wallet;
