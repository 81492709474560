import React, {useEffect, useMemo, useState} from "react";
import {ChainId, ethRegex} from "../../constants";
import {observer} from "mobx-react-lite";
import SendTokenSelect from "@pages/Send/SendTokenSelect";
import SendForm from "@pages/Send/SendForm";
import {IAsset} from "../../types";
import {chainToken} from "@helpers/chains";
import {useStores} from "@hooks/useStores";
import SendConfirm, {SendConfirmProps} from "@pages/Send/SendConfirm";
import {useLocation, useNavigate} from "react-router-dom";
import Swap from "@pages/Swap";
import BigNumber from "bignumber.js";
import useNotification from "@hooks/useNotification";

interface SendParams {
  network: ChainId;
}

export enum SendPageType {
  SEND = 'send',
  SWAP = 'swap',
  CONFIRM = 'confirm',
}

interface SendPageProps {
  type?: SendPageType;
}

export interface SendTokenSelectParams extends SendParams {
  setAsset: (asset: IAsset | null) => void;
  isReceiveSelection: boolean;
}

export interface SendFormParams extends SendParams {
  setNetwork: (network: ChainId) => void;
  amount: string;
  numericAmount: string;
  setAmount: (amount: string) => void;
  address: string;
  setAddress: (address: string) => void;
  asset: IAsset;
  assetReceive: IAsset | null;
  nativeToken?: IAsset;
  slippage: string;
  setSlippage: (value: string) => void;
}

const Send = observer(({type = SendPageType.SEND}: SendPageProps) => {
  const { accountStore, swapStore } = useStores();
  const { assets } = accountStore;
  const { isReceiveSelection, isTokenSelection } = swapStore;
  const navigate = useNavigate();
  const [network, setNetwork] = useState<ChainId>(ChainId.ETHER);
  const [assetSend, setAssetSend] = useState<IAsset>();
  const [assetReceive, setAssetReceive] = useState<IAsset | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [slippage, setSlippage] = useState<string>('');
  const [navAsset, setNavAsset] = useState<IAsset | null>();
  const [navAssetReceive, setNavAssetReceive] = useState<IAsset | null>();
  const location = useLocation();
  const notify = useNotification();

  const numericAmount = useMemo(() => {
    return amount !== '' ? new BigNumber(amount.replace(',', '.')).toString() : '';
  }, [amount]);

  const networkTokens = useMemo(() => {
    return assets.filter((asset) => asset.assetChain === network);
  }, [assets, network]);

  const nativeToken = useMemo(() => {
    const networkToken = chainToken(network);
    return networkTokens.find((asset) => asset.assetSymbol.toLowerCase() === networkToken.toLowerCase());
  }, [networkTokens, network]);

  const handleTokenChange = (newAsset: IAsset | null) => {
    if (!newAsset) {
      swapStore.setIsTokenSelection(false);
      swapStore.setIsReceiveSelection(false);
      return;
    }
    if (!isReceiveSelection && newAsset.assetId !== assetSend?.assetId) {
      setAmount('');
    }
    if (isReceiveSelection) {
      setAssetReceive(newAsset);
    } else {
      setAssetSend(newAsset);
    }
    swapStore.setIsTokenSelection(false);
    swapStore.setIsReceiveSelection(false);
  };

  const handleNetworkChange = (newNetwork: ChainId) => {
    if (newNetwork !== network) {
      setNavAsset(null);
      setNavAssetReceive(null);
      setAmount('');
      setAssetReceive(null);
    }
    setNetwork(newNetwork);
  };

  useEffect(() => {
    if (location.pathname.includes('0x')) {
      const addresses = location.pathname.split('/').filter((p) => ethRegex.test(p));
      if (addresses.length) {
        navigate('/swap', {state: {from: addresses[0], to: addresses.length > 1 ? addresses[1] : undefined}, replace: true});
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (navAsset === undefined) {
      if (location.state?.asset) {
        const newNavAsset = JSON.parse(location.state?.asset);
        if (newNavAsset && newNavAsset?.assetChain) {
          setNavAsset(newNavAsset);
          setNetwork(newNavAsset.assetChain);
        }
      } else {
        setNavAsset(null);
      }
    }
  }, [navAsset, location.state, assets]);

  useEffect(() => {
    if (navAssetReceive === undefined) {
      if (location.state?.assetReceive) {
        const newNavAssetReceive = JSON.parse(location.state?.assetReceive);
        if (newNavAssetReceive && newNavAssetReceive?.assetChain) {
          setNavAssetReceive(newNavAssetReceive);
        }
      } else {
        setNavAssetReceive(null);
      }
    }
  }, [navAssetReceive, location.state, assets]);

  useEffect(() => {
    if (!assetSend && navAsset !== undefined) {
      setAssetSend(navAsset || nativeToken || assets[0]);
    }
  }, [assetSend, nativeToken, assets, navAsset]);

  useEffect(() => {
    if (!nativeToken && assets.length) {
      notify(`You have no native token on this network to make transactions. You need to send some ${chainToken(network)} tokens to your wallet first.`, {type: 'danger'});
      if (assetSend) {
        setNetwork(assetSend.assetChain);
      } else {
        setNetwork(assets[0].assetChain);
        setAssetSend(assets[0]);
      }
    }
  }, [nativeToken, assets, network, assetSend]);

  useEffect(() => {
    if (nativeToken && (assetSend?.assetChain !== nativeToken.assetChain || !assetSend) && navAsset !== undefined) {
      setAssetSend(navAsset || nativeToken);
    }
  }, [nativeToken, assetSend, navAsset]);

  useEffect(() => {
    if (!assetReceive && navAssetReceive !== undefined) {
      setAssetReceive(navAssetReceive);
    }
  }, [assetReceive, navAssetReceive]);

  const selectProps: SendTokenSelectParams = {
    network,
    setAsset: handleTokenChange,
    isReceiveSelection,
  };

  const formProps: SendFormParams = {
    network,
    setNetwork: handleNetworkChange,
    asset: assetSend!,
    assetReceive,
    amount,
    numericAmount,
    setAmount,
    address,
    setAddress,
    nativeToken,
    slippage,
    setSlippage,
  };

  const confirmProps: SendConfirmProps = {
    address,
    amount: numericAmount,
    assetSend: assetSend!,
    assetReceive,
    slippage,
    network,
  };

  return (
    <>
      {(type === SendPageType.SWAP || (type !== SendPageType.CONFIRM)) && (
        <>
          {(isTokenSelection || isReceiveSelection) ? (
            <SendTokenSelect {...selectProps} />
          ) : (
            type === SendPageType.SWAP ? <Swap {...formProps} /> : <SendForm {...formProps} />
            // type === SendPageType.SWAP ? <SwapForm {...formProps} /> : <SendForm {...formProps} />
          )}
        </>
      )}
      {type === SendPageType.CONFIRM && (
        <SendConfirm {...confirmProps} />
      )}
    </>
  )
});

export default Send;
