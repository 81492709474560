import {action, makeAutoObservable, observable} from 'mobx';

export class SwapStore {
  @observable
  private _isTokenSelection = false;

  @observable
  private _isReceiveSelection = false;

  constructor() {
    makeAutoObservable(this);
  }

  get
  isTokenSelection() {
    return this._isTokenSelection;
  }

  get
  isReceiveSelection() {
    return this._isReceiveSelection;
  }

  @action
  setIsTokenSelection(value: boolean) {
    this._isTokenSelection = value;
  }

  @action
  setIsReceiveSelection(value: boolean) {
    this._isReceiveSelection = value;
  }
}
