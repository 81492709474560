import {Variants} from 'framer-motion/dist/index';

export const ENV = {
  API_URL: process.env.REACT_APP_API_URL as string,
  DEX_API_URL: process.env.REACT_APP_DEX_API_URL as string,
  WHALE_SIGNALS_URL: process.env.REACT_APP_WHALE_SIGNALS_API_URL as string,
  BOT_URL: process.env.REACT_APP_BOT_URL as string,
}

export const MONTH_NAMES = [
  "Jan", "Feb", "March", "Apr", "May", "June",
  "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export enum PageRoutes {
  HOME = '/',
  WALLET = '/wallet',
  RESEARCH = '/research',
  PROFILE = '/profile',
  LOGIN = '/login',
  CREATE_WALLET = '/wallet/create',
  IMPORT_PIN = '/wallet/import-pin',
  IMPORT_WALLET = '/wallet/import',
  WALLET_CREATED = '/wallet/created',
  GET_SEED_PHRASE = '/wallet/get-seed-phrase',
  SEED_PHRASE = '/wallet/seed-phrase',
  WALLET_READY = '/wallet/ready',
  ASSET = '/asset/:address',
  RECEIVE = '/receive',
  SEND = '/send',
  SEND_CONFIRM = '/send/confirm',
  SWAP = '/swap',
  TOKEN_SNIPE = '/token-snipe',
  TOKEN_SNIPE_POSITION = '/token-snipe/:address',
  TOKEN_SNIPE_POSITION_BOT = '/token-snipe-bot/:address',
  AUTO_SNIPE = '/auto-snipe',
  LIMIT_ORDERS = '/limit-orders',
  POSITION_PROCESSING = '/position-processing/:id',
  POSITION_PROCESSING_BOT = '/position-processing-bot/:id',
  POSITION_SETTINGS = '/position-settings/:id',
  SETTINGS = '/settings',
  CHANGE_PIN = '/settings/change-pin',
  SNIPE_SETTINGS = '/settings/snipe-settings',
  BOT_SETTINGS = '/settings/bot-settings',
  EXPORT_SEED_PHRASE = '/settings/seed-phrase',
  WHALE_SIGNALS = '/whale-signals',
  AUTO_TRADE = '/auto-trade',
  AUTO_TRADE_CREATE = '/auto-trade/new',
  AUTO_TRADE_FILTERS = '/auto-trade/filters',
  AUTO_TRADE_EDIT = '/auto-trade/edit',
  REFERRAL = '/referral',
  AMOUNT_SETTINGS = '/settings/amount',
}

export enum Sort {
  ASC = 'asc',
  DESC = 'desc',
  DEFAULT = 'default',
}

export const lipsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies aliquam, nunc nisl aliquet nunc, quis aliquam nis`;

export enum ChainId {
  ALL = 'all',
  ETHER = 'ethereum',
  BSC = 'binance-smart-chain',
  POLYGON = 'polygon',
  AVALANCHE = 'avalanche',
  ARBITRUM = 'arbitrum',
  BASE = 'base',
  // AURORA = 'aurora',
  // OPTIMISM = 'optimism',
  // XDAI = 'xdai'
}

export const ethRegex = new RegExp('^0x[a-fA-F0-9]{40}$');
export const numberRegex = /^-?\d*\.?\d*$/;

export const zeroAddress = '0x0000000000000000000000000000000000000000';
export const zeroHash = zeroAddress.replace('0x', '');


const transition = { ease: 'linear', duration: 0.3 };

export const transitionVariants: Variants = {
  enter() {
    return {
      backgroundColor: '#1C1C1C',
      x: 0,
      z: 1,
      transition,
      opacity: 1,
      transitionEnd: {
        // after animation has finished, reset the position to static
        position: 'static',
        opacity: 1,
      },
    }
  },
  initial() {
    return {
      backgroundColor: '#1C1C1C',
      x: '100%',
      z: -1,
      transition,
      // opacity: 0,
    }
  },

  // an updated history.action is provided in AnimatedSwitch via "custom" prop for AnimatePresence
  exit() {
    return {
      backgroundColor: '#1C1C1C',
      x: '-10%',
      z: -1,
      transition,
      opacity: 0,
      transitionEnd: {
        // after animation has finished, reset the position to static
        opacity: 0,
      },
    }
  }
};

// export const transitionVariants: Variants = {
//   enter() {
//     // if isPush is true, this is the eventual destination of the top page (sliding in from the right)
//     // if isPush is false, this is the eventual destination of the bottom page (sliding in from the left)
//     // const isPush = history.action === 'PUSH'
//     const isPush = true;
//
//     return {
//       backgroundColor: '#1C1C1C',
//       x: 0,
//       transition,
//       opacity: 1,
//       transitionEnd: {
//         // after animation has finished, reset the position to static
//         position: 'static',
//         opacity: 1,
//       },
//       // keep top "layer" of animation as a fixed position
//       ...(isPush
//         ? {
//           position: 'fixed',
//           top: 0,
//           right: 0,
//           left: 0,
//           bottom: 0,
//         }
//         : {
//           opacity: 0,
//           // position: 'relative',
//         })
//     }
//   },
//   initial() {
//     // if isPush is true, this is the starting position of top page (sliding in from the right)
//     // if ifPush is false, this is the starting position of bottom page (sliding in from the left)
//     // const isPush = history.action === 'PUSH'
//     const isPush = true;
//
//     return {
//       backgroundColor: '#1C1C1C',
//       x: isPush ? '100%' : '-25%',
//       transition,
//       // keep top "layer" of animation as a fixed position
//       ...(isPush
//         ? {
//           position: 'fixed',
//           top: 0,
//           right: 0,
//           left: 0,
//           bottom: 0,
//           opacity: 0,
//         }
//         : {
//           opacity: 0,
//           boxShadow: isPush ? '0 25px 50px -12px rgba(0, 0, 0, 0.5)' : undefined,
//         })
//     }
//   },
//
//   // an updated history.action is provided in AnimatedSwitch via "custom" prop for AnimatePresence
//   exit(custom) {
//     const action = custom?.action as 'PUSH' | 'POP' || 'PUSH';
//     // if isPop is true, this is the top page being dismissed (sliding to the right)
//     // if isPop is false, this is the botom page being dismissed (sliding to the left)
//     const isPop = action === 'POP'
//
//     return {
//       backgroundColor: '#1C1C1C',
//       x: isPop ? '100%' : '-10%',
//       zIndex: isPop ? 1 : -1,
//       transition,
//
//       // keep top "layer" of animation as a fixed position
//       // this will, however, reset the scroll position of the page being dismissed
//       ...(isPop
//         ? {
//           position: 'fixed',
//           top: 0,
//           right: 0,
//           left: 0,
//           bottom: 0,
//           opacity: 1,
//         }
//         : {
//           opacity: 0,
//           boxShadow: isPop ? '0 25px 50px -12px rgba(0, 0, 0, 0.5)' : undefined,
//
//         })
//     }
//   }
// };

export const portfolioRoutes: string[] = [
  PageRoutes.WALLET,
];
export const researchRoutes: string[] = [
  PageRoutes.RESEARCH,
];
export const tokenTradeRoutes: string[] = [
  PageRoutes.TOKEN_SNIPE,
];
export const autoTradeRoutes: string[] = [
  PageRoutes.AUTO_TRADE,
];
export const profileRoutes: string[] = [
  PageRoutes.PROFILE,
];

