interface BadgeProps {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning';
  label: string;
  className?: string;
}

export default function Badge({variant = 'primary', label, className = 'tx-12'}: BadgeProps) {
  return (
    <div className={`badge bg-semi-${variant}-10 tx-${variant} tx-normal rounded-pill ${className}`}>{label}</div>
  );
};
