import {ApiWalletTransactionsData, ApiWalletTransactionTransfer, OperationType} from "../types/transactions.types";
import BigNumber from "bignumber.js";
import {useEffect, useMemo, useState} from "react";
import {IAsset} from "../types";

const useTransactionDetails = (item: ApiWalletTransactionsData, assets: IAsset[]) => {
  const { chain, status, transferred, operation, sentFrom, sentTo, transactionHash, fee, feeToken, feePrice } = item;
  const [tokenIn, setTokenIn] = useState<ApiWalletTransactionTransfer>();
  const [tokenOut, setTokenOut] = useState<ApiWalletTransactionTransfer>();

  const tokensIn = transferred.filter((item) => item.direction === 'in');
  const tokensOut = transferred.filter((item) => item.direction === 'out');
  const symbolIn = tokenIn?.token;
  const symbolOut = tokenOut?.token;
  const logoIn = tokenIn?.imageUrl || '';
  const logoOut = tokenOut?.imageUrl || undefined;
  const amountIn = new BigNumber(tokenIn?.quantity || 0).toNumber();
  const amountOut = new BigNumber(tokenOut?.quantity || 0).toNumber();
  const amountInUsd = new BigNumber(tokenIn?.quantity || 0).multipliedBy(tokenIn?.price || 0).toNumber();
  const amountOutUsd = new BigNumber(tokenOut?.quantity || 0).multipliedBy(tokenOut?.price || 0).toNumber();
  const address = useMemo(() => {
    if (operation ===  OperationType.Send || operation ===  OperationType.Approve) {
      return sentTo;
    }
    return sentFrom;
  }, [operation, sentTo, sentFrom]);
  const approveToken = operation === OperationType.Approve ? assets.find((asset) => asset.contractAddress === address || asset.fungibleId === address) : undefined;
  const approveLogo = approveToken?.imageUrl;
  const approveSymbol = approveToken?.assetSymbol;
  const approveName = approveToken?.assetName;

  useEffect(() => {
    if (!tokenIn) {
      const nonZeroQtyList = tokensIn.filter((item) => item.quantity);
      if (nonZeroQtyList.length) {
        const resultItem = {
          ...nonZeroQtyList[0],
          quantity: 0,
          value: 0,
          price: 0,
        };
        let pricesSum = new BigNumber(0);

        nonZeroQtyList.forEach((listItem) => {
          resultItem.quantity = new BigNumber(resultItem.quantity).plus(listItem.quantity).toNumber();
          resultItem.value = new BigNumber(resultItem.value).plus(listItem.value).toNumber();
          pricesSum = pricesSum.plus(listItem.price);
        });
        resultItem.price = pricesSum.div(nonZeroQtyList.length).toNumber();

        setTokenIn(resultItem);
      }
    }
  }, [tokenIn, tokensIn]);

  useEffect(() => {
    if (!tokenOut) {
      const nonZeroQtyList = tokensOut.filter((item) => item.quantity);
      if (nonZeroQtyList.length) {
        const resultItem = {
          ...nonZeroQtyList[0],
          quantity: 0,
          value: 0,
          price: 0,
        };
        let pricesSum = new BigNumber(0);

        nonZeroQtyList.forEach((listItem) => {
          resultItem.quantity = new BigNumber(resultItem.quantity).plus(listItem.quantity).toNumber();
          resultItem.value = new BigNumber(resultItem.value).plus(listItem.value).toNumber();
          pricesSum = pricesSum.plus(listItem.price);
        });
        resultItem.price = pricesSum.div(nonZeroQtyList.length).toNumber();

        setTokenOut(resultItem);
      }
    }
  }, [tokenOut, tokensOut]);

  return {
    address,
    chain,
    status,
    operation,
    logoIn,
    logoOut,
    symbolIn,
    symbolOut,
    amountIn,
    amountOut,
    amountInUsd,
    amountOutUsd,
    approveLogo,
    approveSymbol,
    approveName,
    tokenIn,
    tokenOut,
    sentTo,
    sentFrom,
    transactionHash,
    fee,
    feeToken,
    feePrice,
  };
};

export default useTransactionDetails;
