import {GasPrice, GasPriceList} from "../../types";
import FormattedNumber from "@components/common/FormattedNumber";
import React from "react";

interface GasSelectProps {
  gasPrice: GasPriceList;
  setSelectedGas: (gas: GasPrice) => void;
  selectedGas: GasPrice | null;
  title?: string;
}

const GasSelect = ({gasPrice, setSelectedGas, selectedGas, title = 'Priority'}: GasSelectProps) => {
  return (
    <>
      <div className="tx-left">{title}</div>
      <div className="d-flex justify-content-between align-items-center gap-2 pt-2">
        {Object.values(gasPrice).map((gas, index) => (
          <button
            className={`btn btn-transparent tx-center card justify-content-center align-items-center py-2 tx-muted wd-100p ${selectedGas?.gwei === gas.gwei ? 'bg-semi-transparent' : 'bg-semi-transparent-10'}`}
            key={`gas-price-${index}-${gas.gwei}`}
            onClick={() => setSelectedGas(gas)}
          >
            <div className="tx-capitalize tx-12">{Object.keys(gasPrice)[index]}</div>

            <FormattedNumber
              suffix="$"
              value={gas.usd}
              className="d-block tx-17 tx-semibold tx-white"
            />
            <FormattedNumber
              value={gas.gwei}
              postfix="GWEI"
              className="d-block tx-12"
            />
          </button>
        ))}
      </div>
    </>
  );
};

export default GasSelect;
