import React, {useState} from "react";
import NetworkSelection from "@components/NetworkSelection";
import IdentIcon from "@components/common/IndetIcon";
import QRCode from "qrcode.react";
import logo from "@assets/images/logo-dark.svg";
import {getEllipsisTxt} from "@helpers/formatters";
import copy from "@assets/icons/copy.svg";
import share from "@assets/icons/share.svg";
import CopyButton from "@components/common/CopyButton";
import {chainName} from "@helpers/chains";
import {useStores} from "@hooks/useStores";
import {observer} from "mobx-react-lite";
import {ChainId} from "../../constants";

const Receive = observer(() => {
  const { accountStore } = useStores();
  const { address } = accountStore;
  const canShare = !!navigator.canShare && !!navigator.share;
  const [network, setNetwork] = useState<ChainId>(ChainId.ETHER);

  const handleShare = () => {
    try {
      navigator.share({
        title: `My Alpha Wallet ${chainName(network)} address`,
        text: `${address}`,
      });
    } catch (error) {
      console.error(error);
      // notify('Share failed', {duration: 3000, type: 'danger'});
    }
  };

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between">
        <NetworkSelection network={network} onChange={setNetwork} />

        <div className="wd-40 ht-40 rounded-circle overflow-hidden">
          <IdentIcon string={address} className="identicon rounded-circle" size={40} />
        </div>
      </div>

      <CopyButton text={address} className="btn btn-link p-0 wd-100p" noIcon>
        <div className="bg-white rounded-10 d-flex p-4 qr-code my-4 wd-70p mx-auto pos-relative">
          <QRCode
            value={`${address}`}
            size={1000}
            bgColor="#FFFFFF"
            fgColor="#252525"
            imageSettings={{ src: logo, excavate: true, width: 198, height: 150 }}
          />
          <img src={logo} alt="Alpha Wallet" className="qr-code-logo" />
        </div>
      </CopyButton>

      <CopyButton text={address} className="btn btn-link p-0 wd-100p text-decoration-none" noIcon>
        <div className="card tx-center">
          <div className="tx-17 tx-semibold mb-2">
            {getEllipsisTxt(address, 5)}
          </div>
          <div className="tx-muted tx-13">
            {address}
          </div>
        </div>
      </CopyButton>

      <div className={`d-flex justify-content-between align-items-center mt-4 ${canShare ? 'gap-4' : ''}`}>
        <CopyButton text={address} className="btn btn-secondary tx-17 tx-semibold wd-100p px-0 d-flex align-items-center justify-content-center" noIcon>
          <>
            <img src={copy} alt="Copy" width={16} className="me-2" />
            <span>Copy</span>
          </>
        </CopyButton>

        {canShare && (
          <button
            className="btn btn-secondary tx-17 tx-semibold wd-100p px-0 d-flex align-items-center justify-content-center"
            onClick={handleShare}
          >
            <img src={share} alt="Share" width={16} className="me-2" />
            <span>Share</span>
          </button>
        )}
      </div>
    </div>
  );
});

export default Receive;
