import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import TokenLogo from "@components/common/TokenLogo";
import FormattedNumber from "@components/common/FormattedNumber";
import {chainName} from "@helpers/chains";
import {SendTokenSelectParams} from "@pages/Send/index";
import BadgeIcon from "@components/common/BadgeIcon";
import {IAsset} from "../../types";
import useDebounce from "@hooks/useDebounce";
import Preloader from "@components/common/Preloader";

const SendTokenSelect = observer(({ network, setAsset, isReceiveSelection }: SendTokenSelectParams) => {
  const { accountStore } = useStores();
  const { assets } = accountStore;
  const [search, setSearch] = useState<string>('');
  const [foundAssets, setFoundAssets] = useState<IAsset[]>([]);
  const debounceSearch = useDebounce(search, 500);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const allAssets = assets.filter((asset) => asset.assetChain === network);

  const includesSearch = useCallback((value = '') => {
    return (value || '').toLowerCase().includes(search.toLowerCase());
  }, [search]);

  const foundNetworkAssets = useMemo(() => {
    return foundAssets.filter((asset) => asset.assetChain === network);
  }, [foundAssets, network]);

  const filteredAssets = useMemo(() => {
    if (!search) return allAssets.map((asset) => asset.assetId);
    return allAssets.filter(({assetName, assetId, contractAddress}) => includesSearch(assetName) || includesSearch(assetId) || includesSearch(contractAddress)).map((asset) => asset.assetId);
  }, [search, allAssets, includesSearch]);

  const handleNavigate = useCallback((asset: IAsset) => {
    setAsset(asset);
  }, [setAsset]);

  useEffect(() => {
    if (search) {
      setIsLoading(true);
    }
  }, [search]);

  useEffect(() => {
    if (isReceiveSelection && debounceSearch) {
      setIsLoading(true);
      accountStore.searchTokens(debounceSearch).then((assets) => {
        setIsLoading(false);
        setFoundAssets(assets);
      }).catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
    } else {
      setFoundAssets([]);
    }
  }, [isReceiveSelection, debounceSearch]);

  return (
    <div className="d-flex flex-column wd-100p py-3">
      <div className="d-flex mb-3">
        <input
          type="text"
          className="form-control py-1 tx-gray-400 me-2"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Token Name, Address"
        />
        <button className="btn btn-link tx-primary px-0 py-0 tx-13 text-decoration-none" onClick={() => setAsset(null)}>
          Cancel
        </button>
      </div>

      <div className="flex-grow-1 wd-100p mx-auto gradient-transition overflow-auto mb-3">
        <div className="d-flex align-items-center ht-100p flex-row wd-100p">
          {allAssets.map((asset, index) => {
            const {assetId, imageUrl, assetName, assetSymbol} = asset;
            return (
              <div
                className={`${filteredAssets.includes(assetId) ? 'd-flex' : 'd-none'} align-items-center px-3 py-2 rounded-2 bg-secondary ${filteredAssets.length === index + 1 ? '' : 'me-2'}`}
                key={`send-token-list-thumb-${assetId}`}
                onClick={() => handleNavigate(asset)}
              >
                <TokenLogo logo={imageUrl} address={assetId} name={assetName} size={25}/>
                <div className="tx-15 tx-semibold ms-2">
                  {assetSymbol}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {!filteredAssets.length && !isLoading && !foundNetworkAssets.length && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">🔎</BadgeIcon>
          <h1 className="tx-28 mt-4">It's empty :(</h1>
          <div className="tx-muted my-3">Nothing was found for your request. Try specifying a query based on other data</div>
        </div>
      )}

      {isReceiveSelection && (isLoading || foundNetworkAssets.length > 0) && !!search && (
        <>
          <div className="tx-semibold tx-17">
            Network tokens:
          </div>
          {isLoading && (
            <Preloader inline />
          )}
          {!isLoading && (
            <div className="d-flex flex-column wd-100p">
              {foundNetworkAssets.slice(0, 10).map((asset) => {
                const {assetId, assetName, assetSymbol, assetChain, imageUrl} = asset;
                return (
                  <div
                    className={`card d-flex flex-row justify-content-between align-items-center wd-100p mb-3`}
                    key={`found-token-list-${assetId}-${assetSymbol}`}
                    onClick={() => handleNavigate(asset)}
                  >
                    <TokenLogo logo={imageUrl} address={assetId} name={assetName} size={45} chain={assetChain}/>
                    <div className="wd-100p ms-3">
                      <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold mb-1">
                        <div>{assetName}</div>
                        <div>{assetSymbol}</div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
                        <div>{chainName(assetChain)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}

      {filteredAssets.length > 0 && (
        <>
          <div className="tx-semibold tx-17 mt-3">
            Your tokens:
          </div>

          <div className="d-flex flex-column wd-100p">
            {allAssets.map((asset) => {
              const {assetId, value, quantity, assetName, assetSymbol, assetChain, imageUrl} = asset;
              return (
                <div
                  className={`card ${filteredAssets.includes(assetId) ? 'd-flex' : 'd-none'} flex-row justify-content-between align-items-center wd-100p mb-3`}
                  key={`send-token-list-${assetId}-${assetSymbol}`}
                  onClick={() => handleNavigate(asset)}
                >
                  <TokenLogo logo={imageUrl} address={assetId} name={assetName} size={45} chain={assetChain}/>
                  <div className="wd-100p ms-3">
                    <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold mb-1">
                      <div>{assetName}</div>
                      <FormattedNumber
                        value={quantity}
                        postfix={assetSymbol}
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-center tx-13 tx-muted">
                      <div>{chainName(assetChain)}</div>
                      <FormattedNumber
                        value={value}
                        suffix="$"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
});

export default SendTokenSelect;
