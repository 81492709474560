import React from 'react';
import WhaleSignalsPanel from "@pages/WhaleSignals/components/WhaleSignalsPanel";
import alphaIcon from "@assets/whale-signals/alpha.png";

const WhaleSignals = () => {
  return (
    <div className="pb-3">
      <WhaleSignalsPanel bot={{tg_id: 'alpha_web3_bot', name: 'Alpha', icon: alphaIcon, disabled: false}} />
    </div>
  );
};

export default WhaleSignals;
