import {observer} from "mobx-react-lite";
import {BlockchainGas, ISnipeSettings, TokenDetails} from "../../types";
import React, {useCallback, useEffect, useState} from "react";
import {useStores} from "@hooks/useStores";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Preloader from "@components/common/Preloader";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import FormattedNumber from "@components/common/FormattedNumber";
import CopyButton from "@components/common/CopyButton";
import {getEllipsisTxt} from "@helpers/formatters";
import {numberRegex} from "../../constants";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import SnipeRow from "@pages/Profile/SnipeRow";
import InfoTooltip from "@components/common/InfoTooltip";
import cross from "@assets/icons/cross.svg";
import chevron from "@assets/icons/chevron.svg";
import IconWithLoading from "@components/common/IconWithLoading";
import confirmAlert from "@components/ConfirmAlert";
import BigNumber from "bignumber.js";
import useNotification from "@hooks/useNotification";
import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import SettingsInput from "@components/common/SettingsInput";
import useResponseHandler from "@hooks/useResponseHandler";
import {ApiError} from "@helpers/api";
import LabelValueCard from "@components/common/LabelValueCard";
import gasIcon from "@assets/icons/gas-white.svg";
import NumericInput from "@components/common/NumericInput";
import TabsCard from "@components/common/TabsCard";

interface SnipeDetailsProps {
  data?: TokenDetails;
  isBot?: boolean;
}

const SnipeDetails = observer(({data, isBot = false}: SnipeDetailsProps) => {
  const { accountStore, settingsStore } = useStores();
  const {amounts} = settingsStore;
  const { snipeSettings, balance } = accountStore;
  const navigate = useNavigate();
  const params = useParams();
  const [details, setDetails] = useState<TokenDetails>();
  const [settings, setSettings] = useState<ISnipeSettings>();
  const [gas, setGas] = useState<BlockchainGas>();
  const [error, setError] = useState<ApiError | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const { state } = useLocation();
  const WebApp = useWebApp();
  const notify = useNotification();
  const handleResponse = useResponseHandler();

  const setActiveTab = (tab: string) => {
    setSettings({...settings as ISnipeSettings, positionType: tab});
  }

  const handleClose = () => {
    if (isBot && WebApp) {
      WebApp.close();
    } else {
      navigate(-1);
    }
  };

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!settings) {
      return false;
    }
    // @ts-ignore
    const value = settings[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    if (error) {
      console.log(key, !!(error && error.error?.includes(key)));
    }
    return isNaN(value) || !numberRegex.test(value.toString()) || !!(error && error.error?.includes(key));
  }, [settings, error]);

  const handleOnChange = useCallback((key: string, value: string | number | null) => {
    if (error && error.error?.includes(key)) {
      setError(null);
    }
  }, [error]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({...settings, stopLossEnabled: true});
    }
  }, [settings]);

  useEffect(() => {
    if (snipeSettings && !settings && (!state || !state.settings)) {
      const {
        createdAt,
        updatedAt,
        id,
        userId,
        label,
        ...mainData
      } = snipeSettings;
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [snipeSettings, settings, state]);

  useEffect(() => {
    if (!settings && state?.settings) {
      const {
        createdAt,
        updatedAt,
        id,
        userId,
        label,
        ...mainData
      } = state.settings;
      setSettings(JSON.parse(JSON.stringify(mainData)));
    }
  }, [state, settings]);

  useEffect(() => {
    if (data && !details) {
      setDetails(data);
    }
    setIsMounted(true);
  }, [data, details]);

  useEffect(() => {
    if (isMounted && !data && !details && params.hasOwnProperty('address')) {
      accountStore.getTokenDetails(params.address || '')
        .then((response) => {
          if (response) {
            setDetails(response);
          }
        });
    }
  }, [isMounted, data, details, params]);

  useEffect(() => {
    if (!gas) {
      accountStore.getBlockchainGas().then((response) => {
        if (response) {
          setGas(response);
        }
      });
    }
  }, [gas]);

  if (!details || !settings) {
    return (
      <div className="tx-center pt-4">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text="Loading token info..." />
      </div>
    )
  }

  const guards = [
    {
      label: 'MEV Blocker',
      value: settings.mevGuardEnabled,
    },
    {
      label: 'Fail Guard',
      value: settings.failGuard,
    },
    {
      label: 'Anti Rug',
      value: false,
    },
    {
      label: 'Trailing Loss',
      value: settings.trailingStopLossEnabled,
    },
    {
      label: 'Auto Approve',
      value: settings.autoApprove,
    },
  ];

  const confirmItems = [
    {
      label: 'Take Profit',
      value: settings.takeProfitPercent,
      unit: '%',
      altValue: new BigNumber(amount).multipliedBy(settings.takeProfitPercent || 0).dividedBy(100).toNumber(),
      altUnit: 'ETH',
      enabled: settings.takeProfitEnabled,
    },
    {
      label: 'Stop Loss',
      value: settings.stopLossPercent,
      unit: '%',
      altValue: new BigNumber(amount).multipliedBy(settings.stopLossPercent || 0).dividedBy(100).toNumber(),
      altUnit: 'ETH',
      enabled: settings.stopLossEnabled,
    },
    {
      label: 'TP/SL Amount',
      text: `${settings.takeProfitEnabled ? settings.takeProfitSellPercent : 0}/${settings.stopLossEnabled ? settings.stopLossSellPercent : 0}`,
      enabled: settings.takeProfitEnabled || settings.stopLossEnabled,
    },
    {
      label: 'Sell Slippage',
      value: settings.sellSlippage,
      unit: '%',
    },
    {
      label: 'Sell Priority Fee',
      value: settings.sellGasDelta || 'N/A',
      unit: 'GWEI',
      enabled: !!settings.sellGasDelta,
    },
    {
      label: 'Initial Amount',
      value: amount,
      unit: 'ETH',
    },
    {
      label: 'Guard',
      text: guards.filter((guard) => guard.value).map((guard) => guard.label).join(', ') || 'None',
    },
  ];

  const confirmItemsLimitOrder = [
    {
      label: 'Byu By Rate',
      value: settings.limitsBuyByRate,
      unit: '$',
      // altValue: new BigNumber(amount).multipliedBy(settings.takeProfitPercent || 0).dividedBy(100).toNumber(),
      // altUnit: 'ETH',
      enabled: !settings.limitsBuyByCapEnabled,
    },
    {
      label: 'Byu By Cap',
      value: settings.limitsBuyByCap,
      unit: '$',
      // altValue: new BigNumber(amount).multipliedBy(settings.takeProfitPercent || 0).dividedBy(100).toNumber(),
      // altUnit: 'ETH',
      enabled: settings.limitsBuyByCapEnabled,
    },
    {
      label: 'Sell By Rate',
      value: settings.limitsSellByRate,
      unit: '$',
      // altValue: new BigNumber(amount).multipliedBy(settings.takeProfitPercent || 0).dividedBy(100).toNumber(),
      // altUnit: 'ETH',
      enabled: !settings.limitsSellByCapEnabled,
    },
    {
      label: 'Sell By Cap',
      value: settings.limitsSellByCap,
      unit: '$',
      // altValue: new BigNumber(amount).multipliedBy(settings.takeProfitPercent || 0).dividedBy(100).toNumber(),
      // altUnit: 'ETH',
      enabled: settings.limitsSellByCapEnabled,
    },
    {
      label: 'Stop Loss',
      value: settings.stopLossPercent,
      unit: '%',
      altValue: new BigNumber(amount).multipliedBy(settings.stopLossPercent || 0).dividedBy(100).toNumber(),
      altUnit: 'ETH',
      enabled: false,
    },
    {
      label: 'TP/SL Amount',
      text: `${settings.takeProfitEnabled ? settings.takeProfitSellPercent : 0}/${settings.stopLossEnabled ? settings.stopLossSellPercent : 0}`,
      enabled: false,
    },
    {
      label: 'Sell Slippage',
      value: settings.sellSlippage,
      unit: '%',
    },
    {
      label: 'Sell Priority Fee',
      value: settings.sellGasDelta || 'N/A',
      unit: 'GWEI',
      enabled: !!settings.sellGasDelta,
    },
    {
      label: 'Initial Amount',
      value: amount,
      unit: 'ETH',
    },
    {
      label: 'Guard',
      text: guards.filter((guard) => guard.value).map((guard) => guard.label).join(', ') || 'None',
    },
  ];

  const handleSnipe = () => {

    const checks = [];

    checks.push({check: isInvalid('sellSlippage', true), label: 'Sell Slippage'});
    checks.push({check: isInvalid('buySlippage', true), label: 'Buy Slippage'});
    checks.push({check: isInvalid('sellGasDelta'), label: 'Sell Priority Fee'});
    checks.push({check: isInvalid('buyGasDelta'), label: 'Buy Priority Fee'});
    checks.push({check: isInvalid('approveGasDelta'), label: 'Approve Priority Fee'});

    if(settings.positionType == "TakeProfit"){
        checks.push({check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: 'Stop Loss'});
        checks.push({check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: 'Take Profit'});
    }else{
        checks.push({check: isInvalid('limitsBuyByRate', !settings.limitsBuyByCapEnabled), label: 'Buy By Rate'});
        checks.push({check: isInvalid('limitsBuyByCap', settings.limitsBuyByCapEnabled), label: 'Buy By Cap'});
        checks.push({check: isInvalid('limitsSellByRate', !settings.limitsSellByCapEnabled), label: 'Sell By Rate'});
        checks.push({check: isInvalid('limitsSellByCap', settings.limitsSellByCapEnabled), label: 'Sell By Cap'});
    }


    if (!amount) {
      notify('Please enter amount', {type: 'danger'});
      return;
    }

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid or empty', {type: 'danger'});
      return;
    }

    const data = JSON.parse(JSON.stringify(settings));

    if (!settings.stopLossEnabled && !settings.stopLossPercent) {
      data.stopLossPercent = snipeSettings?.stopLossPercent || 0;
    }
    if (!settings.takeProfitEnabled && !settings.takeProfitPercent) {
      data.takeProfitPercent = snipeSettings?.takeProfitPercent || 0;
    }
    if (settings.stopLossPercent && settings.stopLossPercent > 0) {
      data.stopLossPercent = settings.stopLossPercent * -1;
    }

    setIsSaving(true);
    accountStore.openPosition({pairAddress: details.pairAddress, ethAmount: amount, settings: data}).then((response) => {
      setIsSaving(false);
      if (response && response.id) {
        navigate('/position-processing' + (isBot ? '-bot/' : '/') + response.id, {replace: true});
      } else {
        notify(response.data.error, {type: 'danger'});
        setError(response.data);
      }
    }).catch((e) => {
      handleResponse(e);
      console.error(e);
      setIsSaving(false);
      notify('Error. Try again', {type: 'danger'});
    })
  };

  const handleConfirm = () => {

    const checks = [];
    checks.push({check: isInvalid('sellSlippage', true), label: 'Sell Slippage'});
    checks.push({check: isInvalid('buySlippage', true), label: 'Buy Slippage'});
    checks.push({check: isInvalid('sellGasDelta'), label: 'Sell Priority Fee'});
    checks.push({check: isInvalid('buyGasDelta'), label: 'Buy Priority Fee'});
    checks.push({check: isInvalid('approveGasDelta'), label: 'Approve Priority Fee'});

    if(settings.positionType == "TakeProfit"){
      checks.push({check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: 'Stop Loss'});
      checks.push({check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: 'Take Profit'});
    }else{
      checks.push({check: isInvalid('limitsBuyByRate', !settings.limitsBuyByCapEnabled), label: 'Buy By Rate'});
      checks.push({check: isInvalid('limitsBuyByCap', settings.limitsBuyByCapEnabled), label: 'Buy By Cap'});
      checks.push({check: isInvalid('limitsSellByRate', !settings.limitsSellByCapEnabled), label: 'Sell By Rate'});
      checks.push({check: isInvalid('limitsSellByCap', settings.limitsSellByCapEnabled), label: 'Sell By Cap'});
    }


    if (!amount) {
      notify('Please enter amount', {type: 'danger'});
      return;
    }

    if (new BigNumber(balance?.balance || 0).isLessThan(amount)) {
      notify('You have not enough ETH on Ethereum', {type: 'danger'});
      if(process.env.NODE_ENV !== "development")
        return;
    }

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid or empty', {type: 'danger'});
      return;
    }

    confirmAlert({
      title: settings.positionType == "TakeProfit"? 'Open Position (Take Profit) Confirmation': 'Open Position (Limit Order) Confirmation',
      okLabel: 'Open',
      closeButton: true,
      confirmation: (
        <div>
          <div className="d-flex flex-column align-items-center justify-content-center tx-center py-2 wd-100p">
            <HistoryLogo mainLogo={undefined} symbolIn={details.token.symbol} size={45} />
            <div className="tx-semibold tx-28 mt-2 mb-1">Trade {details.token.name}</div>
            <div className="tx-muted tx-17">Open position based on the specified parameters?</div>
          </div>

          <div className="card bg-semi-transparent-10">
            <div className="wd-100p pb-2 mb-1 border-bottom border-semi-transparent">
              <div className="tx-17 tx-semibold">{details.token.name}</div>
              <div className="d-flex align-items-center tx-13 wd-100p">
                <CopyButton text={details.token.address} className="btn btn-link p-0 tx-13 tx-left text-decoration-none" iconClass="tx-white ms-1">
                  <span className="tx-muted">Token: </span>
                  {getEllipsisTxt(details.token.address,3, '0x')}
                </CopyButton>
                <CopyButton text={details.pairAddress} className="btn btn-link p-0 tx-13 tx-left text-decoration-none ms-2" iconClass="tx-white ms-1">
                  <span className="tx-muted">Pair: </span>
                  {getEllipsisTxt(details.pairAddress,3, '0x')}
                </CopyButton>
              </div>
            </div>

            {(settings.positionType == "TakeProfit"? confirmItems: confirmItemsLimitOrder).filter((v) => v.enabled !== false).map(({label, text, value, unit, altValue, altUnit}, index) => (
              <div className={`d-flex align-items-center justify-content-between tx-13 ${index && 'mt-1'}`} key={`snipe-main-details-${label}`}>
                <div className="tx-muted">{label}</div>
                {!!text && <div className="tx-right">{text}</div>}
                {!!value && (
                  <div>
                    <FormattedNumber value={value} postfix={unit} withSign={value < 0} subZeros />
                    {!!altValue && (
                      <FormattedNumber
                        value={altValue}
                        suffix=" ("
                        postfix={`${altUnit})`}
                        withSign={altValue < 0}
                        subZeros
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )
    }).then((response) => {
      if (!!response) {
        handleSnipe();
      }
    });
  }

  return (
    <div className="pt-3 pb-5">

      <div className="d-flex justify-content-end align-items-center tx-13 my-3">
        <img src={gasIcon} alt="gas" width={16} height={16} className="me-2"/>
        <div className="me-1">Gas:</div>
        <FormattedNumber
          value={parseInt(gas?.gasPrice || "0")}
          postfix={gas?.unit.toUpperCase()}
        />
      </div>

      <div className="card mb-4">
        <div className="d-flex flex-row justify-content-between align-items-center wd-100p pb-3">
          <HistoryLogo mainLogo={undefined} symbolIn={details.token.symbol} size={45}/>

          <div className="wd-100p ms-2">
            <div className="tx-17 tx-semibold">{details.token.name}</div>

            <div className="d-flex justify-content-between align-items-center tx-13">
              <CopyButton text={details.token.address} className="btn btn-link p-0 tx-13 tx-left text-decoration-none"
                          iconClass="tx-white ms-1">
                <span className="tx-muted">Token: </span>
                {getEllipsisTxt(details.token.address, 3, '0x')}
              </CopyButton>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center wd-100p gap-3">
          <LabelValueCard label="Liquidity" value={details.liquidity} unit="$"/>
          <LabelValueCard label="Volume" value={details.volume} unit="$"/>
          <LabelValueCard label="MCap" value={details.mcap} unit="$"/>
        </div>
      </div>

      <div className="card mb-4">
        <div className="d-flex align-items-center justify-content-end tx-13 mb-1">
          <div className="ms-0 me-auto">
            Amount
          </div>
          <div className="tx-muted">Balance:</div>
          <FormattedNumber
            value={balance?.balance || 0}
            decimals={4}
            subZeros={new BigNumber(balance?.balance || 0).isLessThan(0.0001)}
            floor
            postfix="ETH"
            className="tx-white ms-1 cur-pointer"
          />
          <FormattedNumber
            value={balance?.balanceInUsd || 0}
            decimals={2}
            className="tx-white ms-1 cur-pointer"
            suffix="($"
            postfix=")"
            noSpace
            floor
            subZeros
          />
        </div>
        <div className="input-group">
          <NumericInput
            value={amount}
            onChange={(v) => setAmount(new BigNumber(v || '').toString())}
            isInvalid={new BigNumber(balance?.balance || 0).isLessThan(amount)}
            placeholder="0.1 ETH"
            className="form-control wd-100p appearance-none"
          />
          {amount !== '' && (
            <div className="input-group-text">
              <img src={cross} alt="Clear" onClick={() => setAmount('')}/>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2 tx-13">
          {amounts.map((a) => (
            <button
              className="btn btn-secondary bg-semi-transparent-10 p-2 justify-content-center align-items-center wd-100p"
              onClick={() => setAmount(a)}
              disabled={new BigNumber(balance?.balance || 0).isLessThan(a)}
              key={a}
            >
              {a} ETH
            </button>
          ))}
        </div>
      </div>

      <TabsCard
          list={[{
            value: 'TakeProfit',
            label: 'Take Profit & Stop Loss'
          },
            {
              value: 'LimitOrder',
              label: 'Limit Order'
            }]}
          active={settings.positionType}
          onClick={setActiveTab}
      >

      <div className="tab-element tab-TakeProfit">
       {/* <div>Take Profit & Stop Loss</div> */}
        <div className="card mt-1 mb-4">
        <SnipeRow>
          <SnipeColumn>
            <div>Take Profit</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.takeProfitEnabled}
              onChange={(e) => setSettings({...settings, takeProfitEnabled: e.target.checked})}
            />
          </SnipeColumn>
          <SnipeColumn>
            <div>Stop Loss</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={settings.stopLossEnabled}
              onChange={(e) => setSettings({
                ...settings,
                stopLossEnabled: e.target.checked,
                trailingStopLossEnabled: false
              })}
            />
          </SnipeColumn>
        </SnipeRow>

        {settings.takeProfitEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of profit at which a sell will be executed"
              isRequired
            >
              Take Profit
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="takeProfitSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of the total token amount to be sold when the specified profit level is reached"
            >
              Take Profit sell %
            </SettingsInput>
          </SnipeRow>
        )}

        {settings.stopLossEnabled && (
          <SnipeRow className="mt-2">
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of loss at which a sell will be executed"
              isRequired
            >
              Stop Loss
            </SettingsInput>

            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="stopLossSellPercent"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of the total token amount to be sold when the specified loss level is reached"
            >
              Stop Loss sell %
            </SettingsInput>
          </SnipeRow>
        )}
      </div>
      </div>

        <div className="tab-element tab-LimitOrder">
          {/* <div>Limit Order</div> */}
          <div className="card mt-1 mb-4">
            <SnipeRow>
              <SnipeColumn>
                <div>Buy by Cap</div>
                <Toggle
                    icons={false}
                    className="styled-toggle my-2"
                    checked={settings.limitsBuyByCapEnabled}
                    onChange={(e) => setSettings({
                      ...settings,
                      limitsBuyByCapEnabled: e.target.checked
                    })}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>Sell by Cap</div>
                <Toggle
                    icons={false}
                    className="styled-toggle my-2"
                    checked={settings.limitsSellByCapEnabled}
                    onChange={(e) => setSettings({
                      ...settings,
                      limitsSellByCapEnabled: e.target.checked
                    })}
                />
              </SnipeColumn>
            </SnipeRow>


                <SnipeRow className="mt-2">
                  {!settings.limitsBuyByCapEnabled && (
                  <SettingsInput
                      data={settings}
                      setHandler={setSettings}
                      dataKey="limitsBuyByRate"
                      isInvalidHandler={isInvalid}
                      placeholder="0$"
                      unit="$"
                      onChange={handleOnChange}
                      tooltip="Rate of token at which a buy will be executed"
                      isRequired={!settings.limitsBuyByCapEnabled}
                  >
                    Buy Rate
                  </SettingsInput>
                  )}

                  {settings.limitsBuyByCapEnabled && (
                  <SettingsInput
                      data={settings}
                      setHandler={setSettings}
                      dataKey="limitsBuyByCap"
                      isInvalidHandler={isInvalid}
                      placeholder="0$"
                      unit="$"
                      onChange={handleOnChange}
                      tooltip="Cap of token at which a buy will be executed"
                      isRequired={settings.limitsBuyByCapEnabled}
                  >
                    Buy Cap
                  </SettingsInput>
                  )}

                  {!settings.limitsSellByCapEnabled && (
                  <SettingsInput
                      data={settings}
                      setHandler={setSettings}
                      dataKey="limitsSellByRate"
                      isInvalidHandler={isInvalid}
                      placeholder="0$"
                      unit="$"
                      onChange={handleOnChange}
                      tooltip="Rate of token at which a sell will be executed"
                      isRequired={!settings.limitsSellByCapEnabled}
                  >
                    Sell Rate
                  </SettingsInput>
                  )}

                  {settings.limitsSellByCapEnabled && (
                  <SettingsInput
                      data={settings}
                      setHandler={setSettings}
                      dataKey="limitsSellByCap"
                      isInvalidHandler={isInvalid}
                      placeholder="0$"
                      unit="$"
                      onChange={handleOnChange}
                      tooltip="Cap of token at which a sell will be executed"
                      isRequired={settings.limitsSellByCapEnabled}
                  >
                    Sell Cap
                  </SettingsInput>
                  )}

                </SnipeRow>

          </div>
        </div>

      <div className={`mb-4 ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
        <div>General settings</div>
        <div className="card mt-1">
          <SnipeRow>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="maxGasPrice"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip="The maximum gas price you're willing to pay for a transaction"
            >
              Max Gas Price
            </SettingsInput>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="approveGasDelta"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
            >
              Approve Priority Fee
            </SettingsInput>
          </SnipeRow>

          <SnipeRow className="mt-3">
            <SnipeColumn>
              <div>MEV Guard</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.mevGuardEnabled}
                onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>Fail Guard</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.failGuard}
                onChange={(e) => setSettings({...settings, failGuard: e.target.checked})}
              />
            </SnipeColumn>
          </SnipeRow>

          {/*<SnipeRow className="mt-3">*/}
          {/*  <SnipeColumn>*/}
          {/*    <div className="tx-gray-300">Anti Rug</div>*/}
          {/*    <Toggle*/}
          {/*      icons={false}*/}
          {/*      className="styled-toggle my-2"*/}
          {/*      disabled*/}
          {/*    />*/}
          {/*  </SnipeColumn>*/}
          {/*  <SnipeColumn>*/}
          {/*    <div>MEV Guard</div>*/}
          {/*    <Toggle*/}
          {/*      icons={false}*/}
          {/*      className="styled-toggle my-2"*/}
          {/*      checked={settings.mevGuardEnabled}*/}
          {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
          {/*    />*/}
          {/*  </SnipeColumn>*/}
          {/*</SnipeRow>*/}

          <SnipeRow className="mt-3">
            <SnipeColumn className="tx-12 tx-semibold">
              <div>Auto Approve</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.autoApprove}
                onChange={(e) => setSettings({...settings, autoApprove: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>Trailing Loss</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.trailingStopLossEnabled}
                onChange={(e) => setSettings({...settings, trailingStopLossEnabled: e.target.checked})}
              />
            </SnipeColumn>
          </SnipeRow>
        </div>

        <div className="mt-4">Buy settings</div>
        <div className="card mt-1">
          <SnipeRow>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="buySlippage"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of price change at which your buy will still be executed"
              isRequired
            >
              Slippage
            </SettingsInput>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="buyGasDelta"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
            >
              Priority Fee
            </SettingsInput>
          </SnipeRow>
        </div>

        <div className="mt-4">Sell settings</div>
        <div className="card mt-1">
          <SnipeRow>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="sellSlippage"
              isInvalidHandler={isInvalid}
              placeholder="1%"
              unit="%"
              onChange={handleOnChange}
              tooltip="The percentage of price change at which your sale will still be executed"
              isRequired
            >
              Slippage
            </SettingsInput>
            <SettingsInput
              data={settings}
              setHandler={setSettings}
              dataKey="sellGasDelta"
              isInvalidHandler={isInvalid}
              placeholder="GWEI"
              onChange={handleOnChange}
              tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
            >
              Priority Fee
            </SettingsInput>
          </SnipeRow>
        </div>
      </div>

      <div className="pb-4 mb-3 tx-13 tx-center border-bottom border-semi-transparent cur-pointer with-hover"
           onClick={() => setIsDetailsOpen((prev) => !prev)}>
        <span>More settings</span>
        <img src={chevron} alt="toggle details" width={16} height={16}
             className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`}/>
      </div>

    </TabsCard>

      <button
        className="btn wd-100p btn-light"
        onClick={handleConfirm}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving}/>
        Trade
      </button>

      <button
        className="btn btn-transparent tx-white wd-100p mt-2"
        onClick={handleClose}
        disabled={isSaving}
      >
        Close
      </button>
    </div>
  );
});

export default SnipeDetails;
