import React, {useMemo, useState} from "react";
import HistoryItem from "@pages/Wallet/components/HistoryItem";
import {format} from "date-fns";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {ApiWalletTransactionsData, Status} from "../../../types/transactions.types";
import TransactionDetails from "@pages/Wallet/components/TransactionDetails";
import {ChainId} from "../../../constants";

const spamWords = [
  'claim',
  'clalm',
  'СLАlМ',
  'official',
  'website',
  'link',
  'reward',
  'giveaway',
  'free',
  'airdrop',
  'promotion',
  'bonus',
  'discount',
  'sale',
  'offer',
  'prize',
  'win',
  'lottery',
  'entry',
  'ticket',
  'www',
].map((v) => v.toLowerCase());

interface TxWithSpam extends ApiWalletTransactionsData {
  isSpam: boolean;
}

interface TimeGroup {
  time: string;
  group: Array<TxWithSpam>;
  chains: ChainId[];
  allSpam: boolean;
}

interface HistoryProps {
  network?: ChainId;
}

const History = observer(({network = ChainId.ALL}: HistoryProps) => {
  const { accountStore } = useStores();
  const { transactions } = accountStore;
  // const [times, setTimes] = useState<string[]>([]);
  // const [groups, setGroups] = useState<Array<ApiWalletTransactionsData[]>>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectedTx, setSelectedTx] = useState<ApiWalletTransactionsData | null>(null);
  const [showAll, setShowAll] = useState<boolean>(false);

  const networkFiltered = useMemo(() => {
    return network === ChainId.ALL ? transactions : transactions.filter((item) => item.chain === network);
  }, [transactions, network]);

  const spamFiltered = useMemo(() => {
    return networkFiltered.filter((item) => {
      return !spamWords.some((word) => item.transferred.some((t) => t.token.toLowerCase().includes(word) && t.token.length > 10));
    });
  }, [networkFiltered]);

  const sorted = useMemo(() => transactions.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  }), [transactions]);

  const queue = useMemo(() => sorted.filter((item) => item.status === Status.Pending), [sorted]);

  const timeGroups: TimeGroup[] = useMemo(() => {
    const times: string[] = [];
    const groups: Array<TxWithSpam[]> = [];
    const chains: Array<ChainId[]> = [];
    sorted.forEach((tx) => {
      if (tx.status === Status.Pending) return;
      const item: TxWithSpam = {
        ...tx,
        isSpam: spamWords.some((word) => tx.transferred.some((t) => t.token.toLowerCase().includes(word) && t.token.length > 10))
      };
      const time = format(new Date(item.date), 'dd.MM.yyyy');
      if (!times.includes(time)) {
        times.push(time);
        groups.push([item]);
        chains.push([item.chain]);
      } else {
        const index = times.indexOf(time);
        groups[index].push(item);
        if (!chains[index].includes(item.chain)) {
          chains[index].push(item.chain);
        }
      }
    });
    return times.map((t, i) => ({time: t, chains: chains[i], group: groups[i], allSpam: groups[i].every((item) => item.isSpam)}));
  }, [sorted]);

  return (
    <div>
      {queue.length > 0 && (
        <div>
          <div className="tx-17 tx-semibold mb-3">
            Queue ({queue.length})
          </div>
          {queue.map((item, index) => (
            <HistoryItem
              item={item}
              isLast={index === queue.length - 1}
              key={`token-history-item-${item.transactionHash}-${index}`}
              onClick={() => setSelectedTx(item)}
            />
          ))}
        </div>
      )}

      <div>
        <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
          <div className="tx-17 tx-semibold">History</div>

          {spamFiltered.length !== transactions.length && (
            <button className="btn btn-link tx-primary tx-right px-0 text-decoration-none" onClick={() => setShowAll(!showAll)}>
              {showAll ? 'Hide spam' : `Show all (${transactions.length - spamFiltered.length} in spam)`}
            </button>
          )}
        </div>
        {timeGroups.map(({time, group, allSpam, chains}, index) => (
          <div key={`history-time-group-${time}-${index}`} className={`mb-5 ${(allSpam && !showAll) || (network !== ChainId.ALL && !chains.includes(network)) ? 'd-none' : ''}`}>
            <div className="tx-center tx-12 tx-muted my-2">
              {time}
            </div>
            {group.map((item, groupIndex) => (
              <HistoryItem
                item={item}
                isLast={groupIndex === group.length - 1}
                key={`token-history-item-${item.transactionHash}-${index}`}
                onClick={() => setSelectedTx(item)}
                hide={(spamFiltered.length !== transactions.length && item.isSpam && !showAll) || (network !== ChainId.ALL && item.chain !== network)}
              />
            ))}
          </div>
        ))}
      </div>

      {selectedTx && (
        <TransactionDetails item={selectedTx} onClose={() => setSelectedTx(null)} />
      )}
    </div>
  )
});

export default History;
