import {IAutoTradeFilters, ISnipeSettings} from "../../types";
import {formatNumber} from "@helpers/numbers";
import BigNumber from "bignumber.js";

export enum StrategyType {
  BLOCKCHAIN = 'BLOCKCHAIN',
  XRAY = 'XRAY',
  WHALE = 'WHALE',
}

export interface StrategyFilter {
  sourceCodeRequired: boolean // default false
  renounceRequired: boolean // default false
  age?: MinMax
  mcap?: MinMax
  liquidity?: LiquidityFilter
  volume?: TimeframeMinMax
  buyTax?: MinMax
  sellTax?: MinMax
  buysAmount?: TimeframeMinMax
  sellsAmount?: TimeframeMinMax,
  holders?: MinMax,
  snipers?: SnipersFilter,
  priceChange?: TimeframeMinMax
}

export interface SnipersFilter {
  amount?: MinMax,
  bribeSum?: MinMax
}

export interface LiquidityFilter {
  initial?: MinMax
  current?: MinMax
  lpLockPercents?: number,
  lpLockDays?: number
}

export interface MinMax {
  min?: number,
  max?: number
}

export interface TimeframeMinMax {
  h24?: MinMax
  h6?: MinMax
  h1?: MinMax
  m5?: MinMax
}

export interface StrategySettings extends ISnipeSettings {
  ethAmount: string;
}

export interface StrategyMainData {
  name: string;
  active: boolean;
  positionSettings: StrategySettings;
  filters: StrategyFilter;
}

export interface StrategyRaw extends StrategyMainData{
  type: StrategyType;
}

export interface Strategy extends StrategyRaw {
  id: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

interface StrategyFilterKeysGroup {
  label: string;
  keys?: string[];
  rows: {
    label?: string;
    key: string;
    type: 'toggle' | 'input';
    placeholder?: string;
    unit?: string;
  }[][];
}

export const strategyFiltersKeys: StrategyFilterKeysGroup[] = [
  {
    label: 'Filters',
    rows: [
      [
        {
          label: 'Source Code Verification',
          key: 'sourceCodeVerification',
          type: 'toggle',
        },
      ],
      [
        {
          label: 'Renounce',
          key: 'renounce',
          type: 'toggle',
        },
      ],
    ],
  },
  {
    label: 'Age',
    rows: [
      [
        {
          label: 'Min',
          key: 'ageMin',
          type: 'input',
          placeholder: 'Hours',
          unit: 'Hours',
        },
        {
          label: 'Max',
          key: 'ageMax',
          type: 'input',
          placeholder: 'Hours',
          unit: 'Hours',
        },
      ],
    ],
  },
  {
    label: 'MCap',
    rows: [
      [
        {
          label: 'Min',
          key: 'mCapMin',
          type: 'input',
          placeholder: 'Min $',
          unit: '$',
        },
        {
          label: 'Max',
          key: 'mCapMax',
          type: 'input',
          placeholder: 'Max $',
          unit: '$',
        },
      ],
    ],
  },
  {
    label: 'Liquidity',
    rows: [
      [
        {
          label: 'Initial liquidity',
          key: 'initialLiquidityMin',
          type: 'input',
          placeholder: 'Min ETH',
          unit: 'ETH',
        },
        {
          key: 'initialLiquidityMax',
          type: 'input',
          placeholder: 'Max ETH',
          unit: 'ETH',
        },
      ],
      [
        {
          label: 'Current liquidity',
          key: 'currentLiquidityMin',
          type: 'input',
          placeholder: 'Min ETH',
          unit: 'ETH',
        },
        {
          key: 'currentLiquidityMax',
          type: 'input',
          placeholder: 'Max ETH',
          unit: 'ETH',
        },
      ],
      [
        {
          label: 'LP Lock',
          key: 'lpLockPercent',
          type: 'input',
          placeholder: '%',
          unit: '%',
        },
        {
          key: 'lpLockDays',
          type: 'input',
          placeholder: 'Days',
          unit: 'Days',
        },
      ],
    ],
  },
  {
    label: 'Volume',
    keys: ['24h', '6h', '1h', '5m'],
    rows: [
      [
        {
          label: 'Volume',
          key: 'volume%keyMin',
          type: 'input',
          placeholder: 'Min $',
          unit: '$',
        },
        {
          key: 'volume%keyMax',
          type: 'input',
          placeholder: 'Max $',
          unit: '$',
        },
      ],
    ],
  },
  {
    label: 'Buy & Sell tax',
    rows: [
      [
        {
          label: 'Buy Tax',
          key: 'buyTaxMin',
          type: 'input',
          placeholder: 'Min %',
          unit: '%',
        },
        {
          key: 'buyTaxMax',
          type: 'input',
          placeholder: 'Max %',
          unit: '%',
        },
      ],
      [
        {
          label: 'Sell Tax',
          key: 'sellTaxMin',
          type: 'input',
          placeholder: 'Min %',
          unit: '%',
        },
        {
          key: 'sellTaxMax',
          type: 'input',
          placeholder: 'Max %',
          unit: '%',
        },
      ],
    ],
  },
  {
    label: 'Buys',
    keys: ['24h', '6h', '1h', '5m'],
    rows: [
      [
        {
          label: 'Buys amount',
          key: 'buys%keyMin',
          type: 'input',
          placeholder: 'Min',
        },
        {
          key: 'buys%keyMax',
          type: 'input',
          placeholder: 'Max',
        },
      ],
    ],
  },
  {
    label: 'Sells',
    keys: ['24h', '6h', '1h', '5m'],
    rows: [
      [
        {
          label: 'Sells amount',
          key: 'sells%keyMin',
          type: 'input',
          placeholder: 'Min',
        },
        {
          key: 'sells%keyMax',
          type: 'input',
          placeholder: 'Max',
        },
      ],
    ],
  },
  {
    label: 'Price',
    keys: ['24h', '6h', '1h', '5m'],
    rows: [
      [
        {
          label: 'Price Change',
          key: 'priceChange%keyMin',
          type: 'input',
          placeholder: 'Min',
          unit: '%',
        },
        {
          key: 'priceChange%keyMax',
          type: 'input',
          placeholder: 'Max',
          unit: '%',
        },
      ],
    ],
  },
  {
    label: 'Holders',
    rows: [
      [
        {
          label: 'Min',
          key: 'holdersMin',
          type: 'input',
          placeholder: 'Min',
        },
        {
          label: 'Max',
          key: 'holdersMax',
          type: 'input',
          placeholder: 'Max',
        },
      ],
    ],
  },
  // {
  //   label: 'Snipers',
  //   rows: [
  //     [
  //       {
  //         label: 'Snipers amount',
  //         key: 'snipersMin',
  //         type: 'input',
  //         placeholder: 'Min',
  //       },
  //       {
  //         key: 'snipersMax',
  //         type: 'input',
  //         placeholder: 'Max',
  //       },
  //     ],
  //     [
  //       {
  //         label: 'Snipers bribes sum',
  //         key: 'snipersBribeSumMin',
  //         type: 'input',
  //         placeholder: 'Min ETH',
  //         unit: 'ETH',
  //       },
  //       {
  //         key: 'snipersBribeSumMax',
  //         type: 'input',
  //         placeholder: 'Max ETH',
  //         unit: 'ETH',
  //       },
  //     ],
  //   ],
  // },
];

export interface StrategyFilterViewKeysGroupItem {
  label: string;
  key: string;
  secondKey?: string;
  type: 'toggle' | 'value' | 'variable';
  unit?: string;
  variables?: string[];
}

interface StrategyFilterViewKeysGroup {
  items: StrategyFilterViewKeysGroupItem[]
}

export const strategyFiltersViewKeys: StrategyFilterViewKeysGroup[] = [
  {
    items: [
      {
        label: 'Source Code Verification',
        key: 'sourceCodeVerification',
        type: 'toggle',
      },
      {
        label: 'Renounce',
        key: 'renounce',
        type: 'toggle',
      },
    ],
  },
  {
    items: [
      {
        label: 'Age',
        key: 'ageMin',
        secondKey: 'ageMax',
        type: 'value',
        unit: 'Hours',
      },
    ],
  },
  {
    items: [
      {
        label: 'MCap',
        key: 'mCapMin',
        secondKey: 'mCapMax',
        type: 'value',
        unit: '$',
      },
    ],
  },
  {
    items: [
      {
        label: 'Initial liquidity',
        key: 'initialLiquidityMin',
        secondKey: 'initialLiquidityMax',
        type: 'value',
        unit: 'ETH',
      },
      {
        label: 'Current liquidity',
        key: 'currentLiquidityMin',
        secondKey: 'currentLiquidityMax',
        type: 'value',
        unit: 'ETH',
      },
      {
        label: 'LP Lock %',
        key: 'lpLockPercent',
        type: 'value',
        unit: '%',
      },
      {
        label: 'LP Lock Days',
        key: 'lpLockDays',
        type: 'value',
        unit: 'Days',
      },
    ],
  },
  {
    items: [
      {
      label: 'Volume',
      key: 'volume%keyMin',
      secondKey: 'volume%keyMax',
      variables: ['24h', '6h', '1h', '5m'],
      type: 'variable',
      unit: '$',
      },
    ],
  },
  {
    items: [
      {
        label: 'Buy Tax',
        key: 'buyTaxMin',
        secondKey: 'buyTaxMax',
        type: 'value',
        unit: '%',
      },
      {
        label: 'Sell Tax',
        key: 'sellTaxMin',
        secondKey: 'sellTaxMax',
        type: 'value',
        unit: '%',
      },
    ],
  },
  {
    items: [
      {
        label: 'Buys amount',
        key: 'buys%keyMin',
        secondKey: 'buys%keyMax',
        variables: ['24h', '6h', '1h', '5m'],
        type: 'variable',
      },
    ],
  },
  {
    items: [
      {
        label: 'Sells amount',
        key: 'sells%keyMin',
        secondKey: 'sells%keyMax',
        variables: ['24h', '6h', '1h', '5m'],
        type: 'variable',
      },
    ],
  },
  {
    items: [
      {
        label: 'Price Change',
        key: 'priceChange%keyMin',
        secondKey: 'priceChange%keyMax',
        variables: ['24h', '6h', '1h', '5m'],
        type: 'variable',
        unit: '%',
      },
    ],
  },
  {
    items: [
      {
        label: 'Holders',
        key: 'holdersMin',
        secondKey: 'holdersMax',
        type: 'value',
      },
    ],
  },
  // {
  //   items: [
  //     {
  //       label: 'Snipers amount',
  //       key: 'snipersMin',
  //       secondKey: 'snipersMax',
  //       type: 'value',
  //     },
  //     {
  //       label: 'Snipers bribes sum',
  //       key: 'snipersBribeSumMin',
  //       secondKey: 'snipersBribeSumMax',
  //       type: 'value',
  //       unit: 'ETH',
  //     },
  //   ],
  // },
];

export const strategyToFlat = (strategy: StrategyFilter): IAutoTradeFilters => {
  const { age, mcap, liquidity, volume, buyTax, sellTax, buysAmount, sellsAmount, holders, snipers, priceChange } = strategy;

  return {
    sourceCodeVerification: strategy.sourceCodeRequired,
    renounce: strategy.renounceRequired,
    ageMin: age?.min ? new BigNumber(age.min).div(3600 * 1000).toString() : null,
    ageMax: age?.max ? new BigNumber(age.max).div(3600 * 1000).toString() : null,
    mCapMin: mcap?.min || null,
    mCapMax: mcap?.max || null,
    volume24hMin: volume?.h24?.min || null,
    volume24hMax: volume?.h24?.max || null,
    volume6hMin: volume?.h6?.min || null,
    volume6hMax: volume?.h6?.max || null,
    volume1hMin: volume?.h1?.min || null,
    volume1hMax: volume?.h1?.max || null,
    volume5mMin: volume?.m5?.min || null,
    volume5mMax: volume?.m5?.max || null,
    buyTaxMin: buyTax?.min || null,
    buyTaxMax: buyTax?.max || null,
    sellTaxMin: sellTax?.min || null,
    sellTaxMax: sellTax?.max || null,
    holdersMin: holders?.min || null,
    holdersMax: holders?.max || null,
    snipersBribeSumMin: snipers?.bribeSum?.min || null,
    snipersBribeSumMax: snipers?.bribeSum?.max || null,
    snipersMin: snipers?.amount?.min || null,
    snipersMax: snipers?.amount?.max || null,
    currentLiquidityMin: liquidity?.current?.min || null,
    currentLiquidityMax: liquidity?.current?.max || null,
    initialLiquidityMin: liquidity?.initial?.min || null,
    initialLiquidityMax: liquidity?.initial?.max || null,
    lpLockDays: liquidity?.lpLockDays || null,
    lpLockPercent: liquidity?.lpLockPercents || null,
    sells24hMin: sellsAmount?.h24?.min || null,
    sells24hMax: sellsAmount?.h24?.max || null,
    sells6hMin: sellsAmount?.h6?.min || null,
    sells6hMax: sellsAmount?.h6?.max || null,
    sells1hMin: sellsAmount?.h1?.min || null,
    sells1hMax: sellsAmount?.h1?.max || null,
    sells5mMin: sellsAmount?.m5?.min || null,
    sells5mMax: sellsAmount?.m5?.max || null,
    buys24hMin: buysAmount?.h24?.min || null,
    buys24hMax: buysAmount?.h24?.max || null,
    buys6hMin: buysAmount?.h6?.min || null,
    buys6hMax: buysAmount?.h6?.max || null,
    buys1hMin: buysAmount?.h1?.min || null,
    buys1hMax: buysAmount?.h1?.max || null,
    buys5mMin: buysAmount?.m5?.min || null,
    buys5mMax: buysAmount?.m5?.max || null,
    priceChange24hMin: priceChange?.h24?.min || null,
    priceChange24hMax: priceChange?.h24?.max || null,
    priceChange6hMin: priceChange?.h6?.min || null,
    priceChange6hMax: priceChange?.h6?.max || null,
    priceChange1hMin: priceChange?.h1?.min || null,
    priceChange1hMax: priceChange?.h1?.max || null,
    priceChange5mMin: priceChange?.m5?.min || null,
    priceChange5mMax: priceChange?.m5?.max || null,
  };
}

export const flatToStrategy = (strategy: IAutoTradeFilters): StrategyFilter => {
  const { sourceCodeVerification, renounce, ageMin, ageMax, mCapMin, mCapMax, volume24hMin, volume24hMax, volume6hMin, volume6hMax, volume1hMin, volume1hMax, volume5mMin, volume5mMax, buyTaxMin, buyTaxMax, sellTaxMin, sellTaxMax, holdersMin, holdersMax, snipersBribeSumMin, snipersBribeSumMax, snipersMin, snipersMax, currentLiquidityMin, currentLiquidityMax, initialLiquidityMin, initialLiquidityMax, lpLockDays, lpLockPercent, sells24hMin, sells24hMax, sells6hMin, sells6hMax, sells1hMin, sells1hMax, sells5mMin, sells5mMax, buys24hMin, buys24hMax, buys6hMin, buys6hMax, buys1hMin, buys1hMax, buys5mMin, buys5mMax, priceChange24hMin, priceChange24hMax, priceChange6hMin, priceChange6hMax, priceChange1hMin, priceChange1hMax, priceChange5mMin, priceChange5mMax } = strategy;

  return {
    sourceCodeRequired: sourceCodeVerification,
    renounceRequired: renounce,
    age: {
      min: ageMin ? new BigNumber(ageMin).multipliedBy(3600 * 1000).toNumber() : undefined,
      max: ageMax ? new BigNumber(ageMax).multipliedBy(3600 * 1000).toNumber() : undefined,
    },
    mcap: {
      min: mCapMin || undefined,
      max: mCapMax || undefined,
    },
    volume: {
      h24: {
        min: volume24hMin || undefined,
        max: volume24hMax || undefined,
      },
      h6: {
        min: volume6hMin || undefined,
        max: volume6hMax || undefined,
      },
      h1: {
        min: volume1hMin || undefined,
        max: volume1hMax || undefined,
      },
      m5: {
        min: volume5mMin || undefined,
        max: volume5mMax || undefined,
      },
    },
    buyTax: {
      min: buyTaxMin || undefined,
      max: buyTaxMax || undefined,
    },
    sellTax: {
      min: sellTaxMin || undefined,
      max: sellTaxMax || undefined,
    },
    holders: {
      min: holdersMin || undefined,
      max: holdersMax || undefined,
    },
    snipers: {
      bribeSum: {
        min: snipersBribeSumMin || undefined,
        max: snipersBribeSumMax || undefined,
      },
      amount: {
        min: snipersMin || undefined,
        max: snipersMax || undefined,
      },
    },
    liquidity: {
      current: {
        min: currentLiquidityMin || undefined,
        max: currentLiquidityMax || undefined,
      },
      initial: {
        min: initialLiquidityMin || undefined,
        max: initialLiquidityMax || undefined,
      },
      lpLockDays: lpLockDays || undefined,
      lpLockPercents: lpLockPercent || undefined,
    },
    sellsAmount: {
      h24: {
        min: sells24hMin || undefined,
        max: sells24hMax || undefined,
      },
      h6: {
        min: sells6hMin || undefined,
        max: sells6hMax || undefined,
      },
      h1: {
        min: sells1hMin || undefined,
        max: sells1hMax || undefined,
      },
      m5: {
        min: sells5mMin || undefined,
        max: sells5mMax || undefined,
      },
    },
    buysAmount: {
      h24: {
        min: buys24hMin || undefined,
        max: buys24hMax || undefined,
      },
      h6: {
        min: buys6hMin || undefined,
        max: buys6hMax || undefined,
      },
      h1: {
        min: buys1hMin || undefined,
        max: buys1hMax || undefined,
      },
      m5: {
        min: buys5mMin || undefined,
        max: buys5mMax || undefined,
      },
    },
    priceChange: {
      h24: {
        min: priceChange24hMin || undefined,
        max: priceChange24hMax || undefined,
      },
      h6: {
        min: priceChange6hMin || undefined,
        max: priceChange6hMax || undefined,
      },
      h1: {
        min: priceChange1hMin || undefined,
        max: priceChange1hMax || undefined,
      },
      m5: {
        min: priceChange5mMin || undefined,
        max: priceChange5mMax || undefined,
      },
    },
  };
}

export const minMaxValue = (min: number | string | null, max: number | string | null, unit?: string) => {
  let result = 'Inactive';
  if (min !== null) {
    result = formatNumber(min, unit);
  }
  if (max !== null) {
    if (min !== null) {
      result += ' ';
    } else {
      result = '';
    }
    result += `> ${formatNumber(max, unit)}`;
  }
  return result;
};
