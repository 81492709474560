export const isMobile = () => {
  let isMob = false;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(globalThis.navigator.userAgent)) {
    isMob = true;
  }

  return isMob;
};

export const isIOS = () => {
  let isIOS = false;

  if (
    /iPad|iPhone|iPod/.test(globalThis.navigator.userAgent) ||
    (globalThis.navigator.userAgent.includes('Mac') && 'ontouchend' in globalThis.document)
  ) {
    isIOS = true;
  }

  return isIOS;
};

// export const hasNotch = () => {
//   const aspectRatio = globalThis.screen.width / globalThis.screen.height;
//   // all iPhones with notch have aspect ratio of 0.462
//   return isIOS() && aspectRatio.toFixed(3) === '0.462';
// };

export const hasNotch = function () {
  let proceed = false;
  const div = document.createElement('div');
  if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'env(safe-area-inset-bottom)';
    proceed = true;
  } else if (CSS.supports('padding-bottom: constant(safe-area-inset-bottom)')) {
    div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
    proceed = true;
  }
  if (proceed) {
    document.body.appendChild(div);
    const calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom);
    document.body.removeChild(div);
    if (calculatedPadding > 0) {
      return true;
    }
  }
  return false;
};

export const isIosChrome = () => {
  let isIosChrome = false;

  if (isIOS() && globalThis.navigator.userAgent.includes('CriOS')) {
    isIosChrome = true;
  }

  return isIosChrome;
};

export const isIosFirefox = () => {
  let isIosFirefox = false;

  if (isIOS() && globalThis.navigator.userAgent.includes('FxiOS')) {
    isIosFirefox = true;
  }

  return isIosFirefox;
};

export const isIosChromeWithNotch = () => isIosChrome() && hasNotch();
export const isIosFirefoxWithNotch = () => isIosFirefox() && hasNotch();

export const isIosNonSafariWithNotch = () => (isIosChrome() || isIosFirefox()) && hasNotch();
