import React, {useCallback, useEffect, useState} from 'react';
import './styles/main.scss';
import MainLayout from "@components/MainLayout";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {
  autoTradeRoutes,
  PageRoutes,
  portfolioRoutes,
  profileRoutes,
  researchRoutes,
  tokenTradeRoutes
} from "./constants";
import Welcome from "@pages/Wallet/Welcome";
import CreateWallet from "@pages/Wallet/CreateWallet";
import WalletCreated from "@pages/Wallet/WalletCreated";
import GetSeedPhrase from "@pages/Wallet/GetSeedPhrase";
import SeedPhrase from "@pages/Wallet/SeedPhrase";
import WalletReady from "@pages/Wallet/WalletReady";
import Wallet from "@pages/Wallet";
import Asset from "pages/Asset";
import Receive from "@pages/Receive";
import Send, {SendPageType} from "@pages/Send";
import Login from "@pages/Login/Login";
import {BackButton, useExpand, useWebApp, WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import {useStores} from "@hooks/useStores";
import useQuery from "@hooks/useQuery";
import RouteWrapper from "@components/RouteWrapper";
import ComingSoon from "@pages/Wallet/ComingSoon";
import Profile from "pages/Profile";
import ChangePin from "@pages/Profile/ChangePin";
import SnipeSettings from "@pages/Profile/SnipeSettings";
import ExportSeedPhrase from "@pages/Profile/ExportSeedPhrase";
import TokenSnipe from "@pages/TokenSnipe";
import PositionProcessing from "@pages/TokenSnipe/PositionProcessing";
import SnipeDetails from "@pages/TokenSnipe/SnipeDetails";
import WhaleSignals from "@pages/WhaleSignals";
import PositionSettings from "@pages/TokenSnipe/components/PositionSettings";
import ImportWallet from "@pages/Wallet/ImportWallet";
import AutoTrade from "@pages/AutoTrade";
import StrategySettings from "@pages/AutoTrade/components/StrategySettings";
import StrategyFilters from "@pages/AutoTrade/components/StrategyFilters";
import Referral from "@pages/Referral";
import Research from "@pages/Research";
import {observer} from "mobx-react-lite";
import AmountSettings from "@pages/Profile/AmountSettings";

import { useIonRouter } from '@ionic/react';
import { App as app} from '@capacitor/app';


const routes = [
  {
    path: PageRoutes.HOME,
    component: <Welcome />,
  },
  {
    path: PageRoutes.LOGIN,
    component: <Login />,
  },
  {
    path: PageRoutes.CREATE_WALLET,
    component: <CreateWallet />,
  },
  {
    path: PageRoutes.IMPORT_PIN,
    component: <CreateWallet isImport />,
  },
  {
    path: PageRoutes.IMPORT_WALLET,
    component: <ImportWallet />,
  },
  {
    path: PageRoutes.WALLET_CREATED,
    component: <WalletCreated />,
  },
  {
    path: PageRoutes.GET_SEED_PHRASE,
    component: <GetSeedPhrase />,
  },
  {
    path: PageRoutes.SEED_PHRASE,
    component: <SeedPhrase isRegister />,
  },
  {
    path: PageRoutes.WALLET_READY,
    component: <WalletReady />,
  },
  {
    path: PageRoutes.WALLET,
    component: <Wallet />,
    isPrivate: true,
  },
  {
    path: PageRoutes.RECEIVE,
    component: <Receive />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SEND,
    component: <Send />,
    isPrivate: true,
  },
  {
    path: '/swap/*',
    component: <Send type={SendPageType.SWAP} />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SEND_CONFIRM,
    component: <Send type={SendPageType.CONFIRM} />,
    isPrivate: true,
  },
  {
    path: PageRoutes.ASSET,
    component: <Asset />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_PROCESSING,
    component: <PositionProcessing />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_PROCESSING_BOT,
    component: <PositionProcessing isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE_POSITION,
    component: <SnipeDetails />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE_POSITION_BOT,
    component: <SnipeDetails isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.TOKEN_SNIPE,
    component: <TokenSnipe />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_SNIPE,
    component: <ComingSoon />,
    isPrivate: true,
  },
  {
    path: PageRoutes.LIMIT_ORDERS,
    component: <ComingSoon />,
    isPrivate: true,
  },
  {
    path: PageRoutes.PROFILE,
    component: <Profile />,
    isPrivate: true,
  },
  {
    path: PageRoutes.RESEARCH,
    component: <Research />,
    isPrivate: true,
  },
  {
    path: PageRoutes.CHANGE_PIN,
    component: <ChangePin />,
    isPrivate: true,
  },
  {
    path: PageRoutes.SNIPE_SETTINGS,
    component: <SnipeSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.BOT_SETTINGS,
    component: <SnipeSettings isBot />,
    isPrivate: true,
  },
  {
    path: PageRoutes.POSITION_SETTINGS,
    component: <PositionSettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.EXPORT_SEED_PHRASE,
    component: <ExportSeedPhrase />,
    isPrivate: true,
  },
  {
    path: PageRoutes.WHALE_SIGNALS,
    component: <WhaleSignals />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE,
    component: <AutoTrade />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_CREATE,
    component: <StrategySettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_EDIT,
    component: <StrategySettings />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AUTO_TRADE_FILTERS,
    component: <StrategyFilters />,
    isPrivate: true,
  },
  {
    path: PageRoutes.REFERRAL,
    component: <Referral />,
    isPrivate: true,
  },
  {
    path: PageRoutes.AMOUNT_SETTINGS,
    component: <AmountSettings />,
    isPrivate: true,
  },
];

const bottomNavRoutes = [
  ...portfolioRoutes,
  // ...researchRoutes,
  ...autoTradeRoutes,
  ...tokenTradeRoutes,
  ...profileRoutes,
  PageRoutes.SWAP,
  PageRoutes.SEND,
];

const preserveRedirectRoutes = [
  '/swap',
  '/position-processing',
  '/position-settings',
  '/token-snipe/',
  '/token-snipe-bot/',
  '/auto-trade/',
  '/test',
];

const noBackRoutes = [
  PageRoutes.HOME,
  PageRoutes.LOGIN,
  PageRoutes.WALLET,
  PageRoutes.WALLET_CREATED,
  PageRoutes.BOT_SETTINGS,
];

const noBackRoutesPatterns = [
  '/position-processing',
  '/position-settings/',
  '/token-snipe-bot/',
];

const App = observer(() => {

  const ionRouter = useIonRouter();
  document.addEventListener('ionBackButton', (ev) => {

    if (!ionRouter.canGoBack()) {
      app.exitApp();
    }else{
      ionRouter.goBack();
    }

    // ev.detail.register(-1, () => {
    //   if (!ionRouter.canGoBack()) {
    //     app.exitApp();
    //   }else{
    //     ionRouter.goBack();
    //   }
    // });
  });


  const [isExpanded, expand] = useExpand();
  const { accountStore, swapStore } = useStores();
  const { profile } = accountStore;
  const { isReceiveSelection, isTokenSelection } = swapStore;
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const WebApp = useWebApp();
  const [backAvailable, setBackAvailable] = useState(false);

  useEffect(() => {
    accountStore.handleGetParams(query);
  }, [accountStore, query]);

  useEffect(() => {
    if (!profile && (routes.find((r) => r.isPrivate && r.path === location.pathname) || preserveRedirectRoutes.some((p) => location.pathname.includes(p)))) {
      accountStore.setRedirectTo(location.pathname);
      console.log('preserve route', location.pathname);
      navigate('/', {replace: true});
    }
  }, [profile, location.pathname]);

  useEffect(() => {
    if (noBackRoutes.includes(location.pathname as string as PageRoutes) || noBackRoutesPatterns.some((p) => location.pathname.includes(p))) {
      setBackAvailable(false);
      console.log('no back route', location.pathname);
    } else {
      if (location.pathname === PageRoutes.SEND_CONFIRM) {
        setBackAvailable(false);
      } else {
        setBackAvailable(true);
      }
      console.log('with back route', location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!isExpanded) {
      expand();
    }
  }, [isExpanded, expand]);

  useEffect(() => {
    if (WebApp && WebApp.hasOwnProperty('setHeaderColor')) {
      WebApp.setHeaderColor('#1C1C1C');
    }
  }, [WebApp]);

  useEffect(() => {
    if (WebApp && WebApp.hasOwnProperty('setBackgroundColor')) {
      if (bottomNavRoutes.some((route) => location.pathname.includes(route))) {
        WebApp.setBackgroundColor('#2C2C2C');
      } else if (location.pathname.includes('/asset/')) {
        WebApp.setBackgroundColor('#282828');
      } else {
        WebApp.setBackgroundColor('#1C1C1C');
      }
    }
  }, [WebApp, location]);

  // useEffect(() => {
  //   if (WebApp && WebApp.hasOwnProperty('enableClosingConfirmation')) {
  //     WebApp.enableClosingConfirmation();
  //   }
  // }, [WebApp]);

  const handleBack = () => {
    if (isTokenSelection) {
      swapStore.setIsTokenSelection(false);
      swapStore.setIsReceiveSelection(false);
      return;
    }
    navigate(-1);
  };

  const wrap = useCallback((element: React.ReactNode) => {
    return (
      <RouteWrapper>{element}</RouteWrapper>
    );
  }, []);

  return (
    <WebAppProvider
      options={{
        smoothButtonsTransition: true,
      }}
    >
      <MainLayout>
        {/*<AnimatedSwitch>*/}
        <Routes>
          {routes.map(({path, component}) => (
            <Route path={path} element={wrap(component)} key={`all-routes-${path}`} />
          ))}
        </Routes>
        {/*</AnimatedSwitch>*/}
      </MainLayout>
      {backAvailable && (
        <BackButton onClick={handleBack} />
      )}
    </WebAppProvider>
  );
});

export default App;
