import React, {useEffect, useState} from 'react'
import useCanvas from "@hooks/useCanvas";

interface CanvasProps extends React.CanvasHTMLAttributes<HTMLCanvasElement> {
  draw: (context: CanvasRenderingContext2D, frameCount: number) => void;
}

function setDPI(canvas: HTMLCanvasElement, dpi: number) {
  // Set up CSS size.
  canvas.style.width = canvas.style.width || canvas.width + 'px';
  canvas.style.height = canvas.style.height || canvas.height + 'px';

  // Get size information.
  var scaleFactor = dpi / 96;
  var width = parseFloat(canvas.style.width);
  var height = parseFloat(canvas.style.height);

  // Backup the canvas contents.
  var oldScale = canvas.width / width;
  var backupScale = scaleFactor / oldScale;
  var backup = canvas.cloneNode(false) as HTMLCanvasElement;
  backup.getContext('2d')?.drawImage(canvas, 0, 0);

  // Resize the canvas.
  var ctx = canvas.getContext('2d');
  canvas.width = Math.ceil(width * scaleFactor);
  canvas.height = Math.ceil(height * scaleFactor);

  // Redraw the canvas image and scale future draws.
  ctx?.setTransform(backupScale, 0, 0, backupScale, 0, 0);
  ctx?.drawImage(backup, 0, 0);
  ctx?.setTransform(scaleFactor, 0, 0, scaleFactor, 0, 0);
}

const Canvas = (props: CanvasProps) => {
  const { draw, ...rest } = props;
  const canvasRef = useCanvas(draw);
  const [dpiSet, setDpiSet] = useState(false);

  useEffect(() => {
    if (!dpiSet && canvasRef.current) {
      setDPI(canvasRef.current, 300);
      setDpiSet(true);
    }
  }, [dpiSet, canvasRef.current]);

  return <canvas ref={canvasRef} {...rest}/>
}

export default Canvas;
