import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Modal} from "react-bootstrap";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import {IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import {useState} from "react";
import useInterval from "@hooks/useInterval";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";

export interface ModalAlertProps {
  okLabel?: string;
  cancelLabel?: string;
  okIcon?: IconDefinition | null;
  cancelIcon?: IconDefinition | null;
  okVariant?: string;
  cancelVariant?: string;
  title?: string;
  confirmation: JSX.Element | string;
  show?: boolean;
  proceed?: (success: boolean) => void;
  enableEscape?: boolean;
  noOk?: boolean;
  noCancel?: boolean;
  closeTimeout?: number;
  closeButton?: boolean;
}

export default function ModalAlert(props: ModalAlertProps) {
  const {
    okLabel = 'Continue',
    cancelLabel = 'Cancel',
    okIcon = null,
    cancelIcon = null,
    okVariant = 'light',
    cancelVariant = 'secondary',
    title = '',
    confirmation,
    show = true,
    proceed = () => {},
    enableEscape = false,
    noOk = false,
    noCancel = false,
    closeTimeout = 0,
    closeButton = false,
  } = props;

  const [timeLeft, setTimeLeft] = useState<number>(closeTimeout);

  useInterval(() => {
    setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
  }, timeLeft > 0 ? 1000 : null);

  const header = title ? (
    <Modal.Header closeButton={closeButton}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  ) : null;

  return (
    <div className="static-modal">
      <Modal
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
        className="pb-3"
      >
        {header}
        <Modal.Body className="pt-1 pb-5">
          {confirmation}

          {(!noOk || !noCancel) && (
            <div>
              {!noOk && (
                <button
                  className={'btn wd-100p mt-2 btn-' + okVariant}
                  onClick={() => proceed(true)}
                  disabled={timeLeft !== 0}
                >
                  {timeLeft !== 0 && (<FontAwesomeIcon icon={faCircleNotch} className="me-2" spin/>)}
                  {timeLeft === 0 && okIcon && (<FontAwesomeIcon icon={okIcon} className="me-2"/>)}
                  {okLabel}
                </button>
              )}
              {!noCancel && (
                <button
                  className={'btn wd-100p mt-3 btn-' + cancelVariant}
                  onClick={() => proceed(false)}
                  disabled={timeLeft !== 0}
                >
                  {timeLeft !== 0 && (<FontAwesomeIcon icon={faCircleNotch} className="me-2" spin/>)}
                  {timeLeft === 0 && cancelIcon && (<FontAwesomeIcon icon={cancelIcon} className="me-2"/>)}
                  {cancelLabel}
                </button>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
