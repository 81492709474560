import React from 'react';

interface ReferralShareCardProps {
  title: string;
  text: string;
  icon: string;
  onClick?: () => void;
}

const ReferralShareCard = ({title, text, icon, onClick}: ReferralShareCardProps) => {
  return (
    <div className="card wd-100p cur-pointer with-hover-bg" onClick={onClick}>
      <div className="d-flex align-items-center justify-content-between tx-15 tx-semibold">
        <div>{title}</div>
        <img src={icon} alt={title} className="wd-25" />
      </div>
      <div className="tx-12 mt-1 tx-muted text-nowrap text-truncate">
        {text}
      </div>
    </div>
  );
};

export default ReferralShareCard;
