import {observer} from "mobx-react-lite";
import ReactInstaStories from "react-insta-stories";
import {Story} from "react-insta-stories/dist/interfaces";
import StoryIntro from "@pages/Onboarding/components/StoryIntro";
import StoryTrade from "@pages/Onboarding/components/StoryTrade";
import StorySwap from "@pages/Onboarding/components/StorySwap";
import StoryAutoTrade from "@pages/Onboarding/components/StoryAutoTrade";

const Onboarding = observer(() => {
  const stories: Story[] = [
    {
      content: StoryIntro,
    },
    {
      content: StoryTrade,
    },
    {
      content: StorySwap,
    },
    {
      content: StoryAutoTrade,
    },
  ];

  return (
    <div className="full-page d-flex justify-content-center" id="onboarding">
      <ReactInstaStories stories={stories} loop storyInnerContainerStyles={{background: '#1C1C1C', padding: '.75rem'}} width="100%" height="100%" />
    </div>
  );
});

export default Onboarding;
