import React, {useState} from "react";
import FormattedNumber from "@components/common/FormattedNumber";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import chevron from "@assets/icons/chevron.svg";
import {useNavigate} from "react-router-dom";
import BigNumber from "bignumber.js";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import StrategyDetailsModal from "@pages/AutoTrade/components/StrategyDetailsModal";
import {formatNumber} from "@helpers/numbers";
import Toggle from "react-toggle";
import {PageRoutes} from "../../../constants";
import ether from "@assets/images/chains/ether.svg";
import {minMaxValue, Strategy, strategyToFlat} from "@pages/AutoTrade/constants";
import Preloader from "@components/common/Preloader";

interface AutoTradeStrategyProps {
  data: Strategy;
  updateStrategy: (strategy: Strategy) => void;
  deleteStrategy: () => void;
}

interface DetailsItem {
  label: string;
  text?: string;
  value?: number | string | null;
  unit?: string;
  altValue?: number;
  altUnit?: string;
  decimals?: number;
  enabled?: boolean;
  isMain?: boolean;
}

const AutoTradeStrategy = observer(({data, updateStrategy, deleteStrategy}: AutoTradeStrategyProps) => {
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const {positionSettings, filters} = data;
  const settings = strategyToFlat(filters);
  const handleResponse = useResponseHandler();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const { id, userId, createdAt, updatedAt, type, ...raw } = data;

  const guards = [
    {
      label: 'MEV Blocker',
      value: positionSettings.mevGuardEnabled,
    },
    {
      label: 'Fail Guard',
      value: positionSettings.failGuard,
    },
    {
      label: 'Anti Rug',
      value: false,
    },
    {
      label: 'Trailing Loss',
      value: positionSettings.trailingStopLossEnabled,
    },
    {
      label: 'Auto Approve',
      value: positionSettings.autoApprove,
    },
  ];

  const details: DetailsItem[] = [
    {
      label: 'Mcap',
      text: settings.mCapMin !== null ? `${formatNumber(settings.mCapMin || 0, '$')} > ${formatNumber(settings.mCapMax || 0, '$')}` : 'Inactive',
      isMain: true,
    },
    {
      label: 'Volume',
      text: minMaxValue(settings.volume24hMin || settings.volume6hMin || settings.volume1hMin || settings.volume5mMin, settings.volume24hMax || settings.volume6hMax || settings.volume1hMax || settings.volume5mMax),
      isMain: true,
    },
    {
      label: 'Liquidity',
      text: minMaxValue(settings.currentLiquidityMin, settings.currentLiquidityMax),
      isMain: true,
    },
    {
      label: 'Take Profit',
      text: 'Inactive',
      enabled: !positionSettings.takeProfitEnabled,
      isMain: true,
    },
    {
      label: 'Stop Loss',
      text: 'Inactive',
      enabled: !positionSettings.stopLossEnabled,
      isMain: true,
    },
    {
      label: 'Take Profit Amount',
      value: positionSettings.takeProfitSellPercent,
      unit: '%',
      enabled: positionSettings.takeProfitEnabled,
      isMain: true,
    },
    {
      label: 'Take Profit %',
      value: positionSettings.takeProfitPercent,
      unit: '%',
      enabled: positionSettings.takeProfitEnabled,
      isMain: true,
    },
    {
      label: 'Stop Loss Amount',
      value: positionSettings.stopLossSellPercent,
      unit: '%',
      enabled: positionSettings.stopLossEnabled,
      isMain: true,
    },
    {
      label: 'Stop Loss %',
      value: positionSettings.stopLossPercent,
      unit: '%',
      enabled: positionSettings.stopLossEnabled,
      isMain: true,
    },
    {
      label: 'Max Gas Price',
      value: positionSettings.maxGasPrice,
      unit: 'GWEI',
      enabled: !!positionSettings.maxGasPrice
    },
    {
      label: 'Priority Fee',
      value: positionSettings.approveGasDelta,
      unit: 'GWEI',
      enabled: !!positionSettings.approveGasDelta
    },
    {
      label: 'Buy Slippage',
      value: positionSettings.buySlippage,
      unit: '%',
      enabled: !!positionSettings.buySlippage
    },
    {
      label: 'Buy Priority Fee',
      value: positionSettings.buyGasDelta,
      unit: 'GWEI',
      enabled: !!positionSettings.buyGasDelta
    },
    {
      label: 'Sell Slippage',
      value: positionSettings.sellSlippage,
      unit: '%',
      enabled: !!positionSettings.sellSlippage
    },
    {
      label: 'Sell Priority Fee',
      value: positionSettings.sellGasDelta,
      unit: 'GWEI',
      enabled: !!positionSettings.sellGasDelta
    },
    {
      label: 'Options',
      text: guards.filter((guard) => guard.value).map((guard) => guard.label).join(', ') || 'None',
    },
  ];

  const toggleActive = () => {
    setIsLoading(true);
    accountStore.updateStrategy(id, {...raw, active: !data.active}).then((result) => {
      setIsLoading(false);
      if (result?.hasOwnProperty('id')) {
        updateStrategy(result);
      } else {
        handleResponse(result.response);
      }
    }).catch((e) => {
      setIsLoading(false);
      console.error(e);
      handleResponse(e.response);
    });
  };

  return (
    <div className="card mb-4 pos-relative overflow-hidden">
      {isLoading && <Preloader text="Updating strategy..." />}
      <div className="d-flex flex-row justify-content-between align-items-center wd-100p pb-3 border-bottom border-semi-transparent">
        <HistoryLogo mainLogo={ether} symbolIn={data.name} size={45} />

        <div className="wd-100p ms-2">
          <div className="d-flex justify-content-between align-items-center tx-17 tx-semibold">
            <div>{data.name}</div>
            <Toggle
              icons={false}
              className="styled-toggle my-2"
              checked={data.active}
              onChange={toggleActive}
            />
          </div>

          <div className="d-flex justify-content-between align-items-center tx-13">
            {/*<CopyButton text={data.token.address} className="btn btn-link p-0 tx-13 tx-left text-decoration-none" iconClass="tx-white ms-1">*/}
            {/*  {getEllipsisTxt(data.token.address,3, '0x')}*/}
            {/*</CopyButton>*/}
            {/*<FormattedNumber*/}
            {/*  value={positionDiffPercent}*/}
            {/*  decimals={1}*/}
            {/*  withSign={!!data.curPrice && !!data.buyPrice}*/}
            {/*  className={positionDiffPercent > 0 ? 'tx-success' : 'tx-danger'}*/}
            {/*  postfix="%"*/}
            {/*/>*/}
          </div>
        </div>
      </div>

      <div className="tx-13 mt-3" onClick={() => setShow(true)}>
        {details.filter(({isMain, enabled}) => enabled !== false && isMain).map(({label, text, value, unit, altValue, altUnit, decimals}, index) => (
          <div className={`d-flex align-items-center justify-content-between ${index && 'mt-1'}`} key={`token-${data.id}-main-details-${label}`}>
            <div className="tx-muted">{label}</div>
            {!!text && <div className="tx-right">{text}</div>}
            {!!value && (
              <div>
                <FormattedNumber
                  value={value}
                  postfix={unit}
                  withSign={value < 0}
                  subZeros={unit === 'ETH' ? new BigNumber(value).abs().isLessThan(0.0001) : true}
                  decimals={decimals}
                />
                {!!altValue && (
                  <FormattedNumber
                    value={altValue}
                    suffix=" ("
                    postfix={`${altUnit})`}
                    withSign={altValue < 0}
                    subZeros
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="my-1 tx-13 cur-pointer" onClick={() => setIsDetailsOpen((prev) => !prev)}>
        <span>More details</span>
        <img src={chevron} alt="toggle details" width={16} height={16} className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`} />
      </div>

      <div className={`tx-13 mb-3 ${isDetailsOpen ? 'd-block' : 'd-none'}`} onClick={() => setShow(true)}>
        {details.filter(({isMain, enabled}) => enabled !== false && !isMain).map(({label, text, value, unit, altValue, altUnit, decimals}, index) => (
          <div className={`d-flex align-items-center justify-content-between ${index && 'mt-1'}`} key={`token-${data.id}-main-details-${label}`}>
            <div className="tx-muted">{label}</div>
            {!!text && <div className="tx-right">{text}</div>}
            {!!value && (
              <div>
                <FormattedNumber
                  value={value}
                  postfix={unit}
                  withSign={value < 0}
                  subZeros={unit === 'ETH' ? new BigNumber(value).abs().isLessThan(0.0001) : true}
                  decimals={decimals}
                />
                {!!altValue && (
                  <FormattedNumber
                    value={altValue}
                    suffix=" ("
                    postfix={`${altUnit})`}
                    withSign={altValue < 0}
                  />
                )}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-between align-items-center wd-100p tx-semibold tx-13 pt-3 border-top border-semi-transparent mt-3 tx-13 tx-semibold">
        <div
          className="tx-danger cur-pointer"
          onClick={deleteStrategy}
        >
          Delete strategy
        </div>

        <div className="cur-pointer tx-primary" onClick={() => navigate(PageRoutes.AUTO_TRADE_EDIT, {state: {strategy: data}})}>
          Edit strategy
        </div>
      </div>
      <StrategyDetailsModal data={data} show={show} setShow={setShow} handleDelete={deleteStrategy} toggleActive={toggleActive} isLoading={isLoading} />
    </div>
  )
});

export default AutoTradeStrategy;
