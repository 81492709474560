import React, {useCallback, useEffect, useMemo, useState} from "react";
import NetworkSelection from "@components/NetworkSelection";
import IdentIcon from "@components/common/IndetIcon";
import arrow from "@assets/icons/arrow-down-dark.svg";
import qrScan from "@assets/icons/qr-scan.svg";
import gasIcon from "@assets/icons/gas.svg";
import sendArrow from "@assets/images/send-arrow.svg";
import FormattedNumber from "@components/common/FormattedNumber";
import BigNumber from "bignumber.js";
import {getEllipsisTxt} from "@helpers/formatters";
import Preloader from "@components/common/Preloader";
import {useNavigate} from "react-router-dom";
import {ethRegex, PageRoutes, zeroAddress} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import {chainToken} from "@helpers/chains";
import TokenLogo from "@components/common/TokenLogo";
import {SendFormParams} from "@pages/Send/index";
import {roundDown, toWei} from "@helpers/numbers";
import {useScanQrPopup} from "@vkruglikov/react-telegram-web-app";
import {ApiGasPrice, GasPrice} from "../../types";
import useResponseHandler from "@hooks/useResponseHandler";
import {greaterThan} from "@helpers/bignumber";
import useDebounce from "@hooks/useDebounce";
import GasSelect from "@pages/Send/GasSelect";

const SendForm = observer((props: SendFormParams) => {
  const { network, setNetwork, asset, nativeToken, address, setAddress, amount, numericAmount, setAmount } = props;
  const { accountStore, swapStore } = useStores();
  const [show, close] = useScanQrPopup();
  const { assetsLoaded, address: accountAddress } = accountStore;
  const navigate = useNavigate();
  const [selectedGas, setSelectedGas] = useState<GasPrice | null>(null);
  const [gas, setGas] = useState<ApiGasPrice | null>(null);
  const [isGasLoaded, setIsGasLoaded] = useState<boolean>(true);
  const [showGasPrices, setShowGasPrices] = useState<boolean>(false);
  const handleResponse = useResponseHandler();
  const debounceAmount = useDebounce(numericAmount, 1000);

  const isAddressValid = useCallback((value: string) => {
    return ethRegex.test(value);
  }, []);

  const filledAddress = useMemo(() => {
    const arr = new Array(40).fill('0');
    arr.splice(0, address.slice(2).length, ...address.slice(2).split(''));
    return arr.join('');
  }, [address]);

  useEffect(() => {
    if (isAddressValid(address) && debounceAmount && !(new BigNumber(debounceAmount).eq(0))) {
      setIsGasLoaded(false);
      accountStore
        .calculateGas({
          walletAddress: accountAddress,
          tokenAmount: toWei(debounceAmount, asset.decimals),
          tokenAddress: asset.contractAddress || zeroAddress,
          network,
          receiverAddress: address,
        })
        .then((response) => {
          setIsGasLoaded(true);
          if (!response?.gasPrice) {
            handleResponse(response);
            setGas(null);
            return;
          }
          setGas(response);
          setSelectedGas(response.gasPrice.medium);
        })
        .catch((e) => {
          setIsGasLoaded(true);
          handleResponse(e);
        });
    } else {
      setGas(null);
    }
  }, [address, accountAddress, debounceAmount, asset, network, isAddressValid, accountStore, handleResponse, nativeToken]);

  const amountValue = useMemo(() => {
    let value = roundDown(numericAmount, asset?.decimals < 4 ? asset.decimals : 4);
    const _amount = new BigNumber(numericAmount).toNumber();

    if (!value && _amount > value) {
      value = _amount;
    }

    const stringValue = new BigNumber(value).toFixed();

    const integer = parseInt(stringValue);
    const digits = stringValue.split('.')[1];
    const result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
    return `${result}${digits ? '.' + digits.substring(0, 4) : ''}`;
  }, [numericAmount, asset]);

  const onScan = (result: string) => {
    let value = result;
    if (result.split(':').length > 1) {
      value = result.split(':')[1];
    }
    if (isAddressValid(value)) {
      setAddress(value);
      close();
    }
  }

  const feeAmount = useMemo(() => {
    return gas && selectedGas ? new BigNumber(gas.optimalGasLimit).times(selectedGas.gwei).times(10 ** 9).div(10 ** 18).toString() : null;
  }, [gas, selectedGas]);

  const isInvalidAmount = useMemo(() => {
    return !!numericAmount && !!asset?.quantity && greaterThan(numericAmount, asset.quantity);
  }, [numericAmount, asset]);

  const isEnoughAmount = useMemo(() => {
    if (!feeAmount) {
      return 0;
    }
    if (!nativeToken) {
      return 0;
    }
    const reqAmount = asset.assetId === nativeToken.assetId ? new BigNumber(numericAmount || 0).plus(feeAmount || 0).toString() : feeAmount || 0;
    return new BigNumber(nativeToken.quantity).minus(reqAmount).toNumber();
  }, [asset, numericAmount, nativeToken, feeAmount]);

  const isNativeTokenEnough = useMemo(() => {
    return isEnoughAmount >= 0;
  }, [isEnoughAmount]);

  const handleSubmit = () => {
    if (!gas || !numericAmount || !isAddressValid || !isNativeTokenEnough || isInvalidAmount) {
      return;
    }
    navigate(PageRoutes.SEND_CONFIRM, {state: {amount: numericAmount, address, network, assetId: asset.assetId, gas, selectedGas}});
  };

  if (!asset) {
    return (
      <div className="py-3">
        <div className="d-flex justify-content-between">
          <NetworkSelection network={network} onChange={setNetwork} />

          <div className="wd-40 ht-40 rounded-circle overflow-hidden">
            <IdentIcon string={accountAddress} className="identicon rounded-circle" size={40} />
          </div>
        </div>

        <div className="tx-22 tx-semibold my-4">Send</div>

        <div className="card bg-transparent border border-solid border-warning tx-warning d-block mt-4">
          <div className="tx-20 tx-semibold">You have no tokens to send.</div>
          <div className="tx-white">You need to receive tokens first, so you can send them later.</div>
        </div>

        <button
          className="btn btn-light wd-100p mt-4"
          onClick={() => navigate(PageRoutes.RECEIVE)}
        >
          Receive
        </button>
      </div>
    );
  }

  if (!assetsLoaded) {
    <div className="py-4">
      <Preloader
        className="d-flex flex-column"
        iconSize={65}
        textClass="mt-3"
        text="Loading assets..."
        inline
      />
    </div>
  }

  return (
    <div className="py-3">
      <div className="d-flex justify-content-between">
        <NetworkSelection network={network} onChange={setNetwork} />

        <div className="wd-40 ht-40 rounded-circle overflow-hidden">
          <IdentIcon string={accountAddress} className="identicon rounded-circle" size={40} />
        </div>
      </div>

      <div className="tx-22 tx-semibold my-4">Send</div>

      <div className="card p-0">
        <div className="p-3">
          <div className="d-flex justify-content-end align-items-center">
            <div className="tx-17 tx-semibold ms-0 me-auto">Token</div>
            {!!numericAmount && (
              <button
                className="btn btn-outline-gray-light tx-13 tx-semibold px-3 me-2"
                onClick={() => setAmount('')}
              >
                Clear
              </button>
            )}
            <button
              className="btn btn-outline-gray-light tx-13 tx-semibold px-3"
              onClick={() => setAmount(asset.quantity)}
            >
              Max
            </button>
          </div>

          <div className="d-flex justify-content-start align-items-center mt-3">
            <div className="d-flex justify-content-start align-items-center" onClick={() => swapStore.setIsTokenSelection(true)}>
              <TokenLogo logo={asset.imageUrl} address={asset.assetId} name={asset.assetName} size={35} />
              <div className="tx-17 ms-3">{asset.assetSymbol}</div>
              <img src={arrow} alt="Arrow" className="ms-1" />
            </div>

            <input
              // type="number"
              type="text"
              className={`form-control bg-transparent border-0 tx-20  tx-right py-0 px-0 appearance-none ${isInvalidAmount ? 'tx-danger-f' : 'tx-muted'}`}
              value={numericAmount === asset.quantity ? amountValue : amount}
              placeholder="0"
              // pattern="[0-9]+(\\.[0-9][0-9]?)?"
              // pattern="[0-9]+([.,][0-9][0-9]?)?"
              inputMode="decimal"
              // lang="en-150"
              // step="0.01"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <div className={`d-flex justify-content-start align-items-center tx-13 mt-2`}>
            <FormattedNumber
              suffix="Bal: "
              decimals={4}
              floor={true}
              value={asset.quantity}
              className={`${asset.quantity === '0' ? 'tx-muted' : ''}`}
            />
            <FormattedNumber
              suffix="("
              value={asset.value}
              postfix="$)"
              className={`tx-muted ms-1 ${asset.quantity === '0' ? 'd-none' : ''}`}
            />
            <FormattedNumber
              suffix="$"
              value={new BigNumber(numericAmount || 0).times(asset.price).toNumber()}
              className={`ms-auto me-0 ${!numericAmount ? 'tx-muted' : ''} ${isInvalidAmount ? 'tx-danger-f' : ''}`}
            />
          </div>
        </div>

        <div className="swap-separator">
          <img src={sendArrow} alt="Swap" className="wd-40 ht-40" />
        </div>

        <div className="p-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="tx-17 tx-semibold">Receiver</div>
            <img src={qrScan} alt="QR Scan" className="wd-25 ht-25" onClick={() => show({text: 'Scan address QR code'}, onScan)} />
          </div>

          <input
            type="text"
            className={`form-control bg-transparent border-0 tx-22 py-0 px-0 appearance-none mt-3 ${!address ? 'tx-muted' : 'tx-white'}`}
            value={address}
            placeholder="Address, domain or idenity"
            onChange={(e) => setAddress(e.target.value)}
          />

          <div className={`d-flex justify-content-between align-items-center tx-13 mt-2 ${!address ? 'tx-muted' : 'tx-white'}`}>
            0x{getEllipsisTxt(filledAddress, 4)}
          </div>
        </div>
      </div>

      {isAddressValid(address) && (!isGasLoaded || gas) && (
        <div className="card mt-4 tx-13 wd-100p d-flex flex-column">
          <button
            className="btn btn-transparent px-0 py-0 d-flex flex-row justify-content-between align-items-center wd-100p"
            onClick={() => setShowGasPrices((prev) => isGasLoaded ? !prev : false)}
          >
            <div className={`ms-0 me-auto ${!gas ? 'blurred-row' : ''}`}>
              <FormattedNumber
                value={feeAmount}
                decimals={6}
                postfix={chainToken(network)}
              />
              <FormattedNumber
                value={feeAmount && gas ? new BigNumber(feeAmount).times(gas.nativeCoinUsdPrice).toNumber() : null}
                suffix="($"
                postfix=")"
                className="tx-muted ms-2"
              />
            </div>
            <img src={gasIcon} alt="Gas" className="ms-auto me-2" />
            {!selectedGas && (
              <Preloader
                text=""
                iconSize={25}
                iconClass="d-block"
                inline
              />
            )}
            {!!selectedGas && (
              <FormattedNumber
                value={selectedGas.gwei}
              />
            )}
            <img src={arrow} alt="Detailed gas prices" className={`ms-2 will-rotate ${showGasPrices ? 'rotate-180' : ''}`} />
          </button>

          <div className={`pt-3 tx-muted wd-100p ${showGasPrices ? 'd-block' : 'd-none'}`}>
            {gas && <GasSelect
              gasPrice={gas.gasPrice}
              selectedGas={selectedGas}
              setSelectedGas={setSelectedGas}
            />}
          </div>
        </div>
      )}

      {!isNativeTokenEnough && (
        <div className="card bg-transparent border border-solid border-danger tx-muted d-block mt-4">
          <div>You have not enough {chainToken(network)} to pay the transaction fee.</div>
          <div>
            You need
            <FormattedNumber
              value={isEnoughAmount * -1}
              decimals={6}
              postfix={chainToken(network)}
              className="tx-white mx-2"
            />
            more for gas to complete this swap
          </div>
        </div>
      )}

      <button
        className="btn btn-light wd-100p mt-4"
        disabled={!gas || !numericAmount || !isAddressValid || !isNativeTokenEnough || isInvalidAmount}
        onClick={handleSubmit}
      >
        Continue
      </button>
    </div>
  );
});

export default SendForm;
