import React, {useCallback, useEffect, useState} from "react";
import {ReactComponent as Ellipse} from '@assets/icons/ellipse.svg';

interface PinInputProps {
  length: number;
  onChange: (value: string) => void;
  onComplete: (value: string) => void;
  isInvalid?: boolean;
  disabled?: boolean;
}

const numbers =  Array.from({ length: 9 }, (_, i) => (i + 1).toString());

const PinInput = ({ length, onChange, onComplete, isInvalid, disabled }: PinInputProps) => {
  const [value, setValue] = useState('');
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleChange = (value: string) => {
    if (disabled) return;
    setValue(value);
    onChange(value);
    setIsDirty(true);

    if (value.length === length) {
      onComplete(value);
    }
  }

  useEffect(() => {
    if (isInvalid) {
      setValue('');
      setIsDirty(false);
    }
  }, [isInvalid]);

  const handleKeyDown = useCallback((e: any) => {
    if (e.key === 'Backspace') {
      handleChange(value.slice(0, -1));
    } else if ([...numbers, '0'].includes(e.key)) {
      handleChange(value + e.key);
    }
  }, [value, numbers]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [handleKeyDown]);

  return (
    <div className="pin-input d-flex flex-column justify-content-between ht-100p">
      <div className="pin-input__input d-flex justify-content-between px-3 mb-4 gap-3 wd-100p">
        {Array.from({ length }, (_, i) => {
          let bg = 'bg-secondary';
          if (isDirty && ((!value[i] && i === 0) || (value[i - 1] && !value[i]))) {
            bg = 'bg-gray-600';
          } else if (isInvalid && !isDirty) {
            bg = 'tx-danger bg-semi-danger-10';
          }
          return (
            <div
              className={`pin-input__input__item wd-100p ht-55 rounded-2 d-flex justify-content-center align-items-center ${bg}`}
              key={i}
            >
              {value[i] ? <Ellipse /> : ''}
            </div>
          );
        })}
      </div>

      <div className={`pin-input__numbers mx-auto d-flex flex-wrap justify-content-between mb-5 pb-5 wd-100p row ${disabled ? 'opacity-50' : ''}`}>
        {numbers.map((number) => (
          <div className="col-4 px-2" key={number}>
            <div
              className="pin-input__numbers__item btn btn-secondary px-1 mb-3 wd-100p"
              onClick={() => {
                if (value.length < length) {
                  handleChange(value + number);
                }
              }}
            >
              {number}
            </div>
          </div>
        ))}
        <div className="col-4 px-2">
          <div
            className="pin-input__numbers__item btn btn-secondary px-1 mb-3 wd-100p"
            onClick={() => {
              handleChange('');
            }}
          >
            Clear
          </div>
        </div>
        <div className="col-4 px-2">
          <div
            className="pin-input__numbers__item btn btn-secondary px-1 mb-3 wd-100p"
            onClick={() => {
              handleChange(value + '0');
            }}
          >
            0
          </div>
        </div>
        <div className="col-4 px-2">
          <div
            className="pin-input__numbers__item btn btn-secondary px-1 mb-3 wd-100p"
            onClick={() => {
              handleChange(value.slice(0, -1));
            }}
          >
            Delete
          </div>
        </div>
    </div>
  </div>
  );
}

export default PinInput;
