import { useRef, useEffect } from 'react'

type DrawFunction = (context: CanvasRenderingContext2D, frameCount: number) => void;

const useCanvas = (draw: DrawFunction) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    let frameCount = 0;
    let animationFrameId: number;

    const render = () => {
      if (!context) return;
      frameCount++
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    }
  }, [draw])

  return canvasRef;
}

export default useCanvas;
