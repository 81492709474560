import React, {useEffect, useMemo, useState} from "react";
import regi from "@assets/images/regi.png";
import {useLocation, useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {observer} from "mobx-react-lite";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import useNotification from "@hooks/useNotification";
import useResponseHandler from "@hooks/useResponseHandler";
import IconWithLoading from "@components/common/IconWithLoading";

const ImportWallet = observer(() => {
  const { accountStore } = useStores();
  const { profile } = accountStore;
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotification();
  const handleResponse = useResponseHandler();
  const [phrase, setPhrase] = useState<string>('');
  const [words, setWords] = useState<string[]>([]);
  const [pin, setPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isPhraseValid = useMemo(() => {
    return words.length === 12 && words.every((word) => !!word);
  }, [words]);

  const handlePreview = () => {
    const phraseWords = phrase.toLowerCase().split(' ');
    if (phraseWords.length !== 12) {
      notify('Please enter 12 words', {type: 'danger'});
      return;
    }
    setWords(phraseWords);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    accountStore
      .register(pin, words.join(' '))
      .then(() => {
        accountStore.loadUser().then(() => {
          navigate(PageRoutes.WALLET_READY, {replace: true});
        });
      })
      .catch((e) => {
        setIsLoading(false);
        setIsInvalid(true);
        handleResponse(e.response);
      });
  }

  useEffect(() => {
    if (pin && isPhraseValid && profile) {
      navigate(PageRoutes.WALLET_READY, {replace: true});
    }
  }, [pin, isPhraseValid, profile]);

  useEffect(() => {
    if (location.state && location.state.pin) {
      setPin(location.state.pin);
    } else {
      navigate(PageRoutes.IMPORT_PIN, {replace: true});
    }
  }, [location]);

  if (words.length) {
    return (
      <div className="tx-center full-page d-flex flex-column pb-5">
        <h1 className="tx-28 mt-5 mb-0">
          Preview
        </h1>

        <div className="tx-muted my-1">
          Check if you entered your seed phrase correctly. You can edit the words
        </div>

        <div className="card mt-3">
          <div className="d-flex flex-wrap">
            {words.map((word, index) => (
              <div className="wd-50p mb-2" key={`seed-phrase-${index}`}>
                <div className={`pos-relative ${index % 2 === 0 ? 'me-1' : 'ms-1'}`}>
                  <div className="tx-muted rounded bg-gray-600 wd-20 ht-20 d-flex tx-center align-items-center justify-content-center tx-12 seed-phrase-label">
                    {index + 1}
                  </div>
                  <input
                    type="text"
                    className={`form-control tx-13 pe-2 py-2 border-0 bg-transparent rounded border-1 border-solid border-focus ${isInvalid || !word ? 'border-danger' : ''}`}
                    value={word}
                    disabled={isLoading}
                    onChange={(e) => {
                      setIsInvalid(false);
                      setWords((prev) => {
                        const newWords = [...prev];
                        newWords[index] = e.target.value;
                        return newWords;
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <button
          className="btn btn-light wd-100p mt-2"
          onClick={handleSubmit}
          disabled={isLoading || !isPhraseValid || isInvalid}
        >
          <IconWithLoading isLoading={isLoading} />
          Import
        </button>
      </div>
    );
  }

  return (
    <div className="tx-center full-page d-flex flex-column pb-5">
      <h1 className="tx-28 mt-5">
        Type your seed phrase
      </h1>

      <div className="tx-muted my-3">
        Use «Space» between words in recovery phrase
      </div>

      <div className="ht-130 d-flex align-items-center justify-content-center tx-center my-2 pt-5 mt-4">
        <textarea
          className="form-control tx-22 px-0 py-0 tx-center border-0 bg-transparent ht-130"
          placeholder="Type Phrase"
          value={phrase}
          onChange={(e) => setPhrase(e.target.value)}
        />
      </div>

      <button
        className="btn btn-light wd-100p mt-2"
        onClick={handlePreview}
      >
        Continue
      </button>
    </div>
  );
});

export default ImportWallet;
