import React from "react";
import {useNavigate} from "react-router-dom";
import regi from "@assets/images/regi.png";
import {PageRoutes} from "../../../constants";
import {motion} from "framer-motion";

const StoryIntro = () => {
  const navigate = useNavigate();
  return (
    <div className={`d-flex flex-column align-items-center justify-content-center ht-100p wd-100p`}>
      <motion.img
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.1, duration: 0.5}}
        src={regi}
        alt="Pro DEX for everyone"
        className="wd-90p px-0 pos-absolute top-0 start-5"
      />
      <img src={regi} alt="Pro DEX for everyone" className="wd-90p mt-4 opacity-0" />

      <motion.h2
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.2, duration: 0.5}}
        className="tx-28 mt-auto mb-0">Pro DEX for everyone</motion.h2>

      <motion.p
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.3, duration: 0.5}}
        className="tx-16 mt-2 mb-3 tx-muted">
        Are You Ready To The Moon?
      </motion.p>

      <div className="wd-100p mt-auto mb-auto">
        <motion.button
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 0.4, duration: 0.5}}
          className="btn btn-light wd-100p"
          onClick={() => navigate(PageRoutes.CREATE_WALLET)}
        >
          Create Wallet
        </motion.button>

        <motion.button
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{delay: 0.5, duration: 0.5}}
          className="btn btn-secondary wd-100p mt-3"
          onClick={() => navigate(PageRoutes.IMPORT_PIN)}
        >
          Import Wallet
        </motion.button>
      </div>
    </div>
  );
}

export default StoryIntro;
