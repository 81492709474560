import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    {/*<TelegramProvider>*/}
      <App />

      <Toaster
        toastOptions={{
          style: {
            border: 'none',
          },
          success: {
            style: {
              background: '#1C1C1C',
              color: '#32D74B',
            },
          },
          error: {
            style: {
              background: '#1C1C1C',
              color: '#FF453A',
            },
          },
        }}/>
      {/*</TelegramProvider>*/}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
