import React, {useState} from "react";
import {PositionModel} from "../../../types";
import {observer} from "mobx-react-lite";
import {Modal} from "react-bootstrap";
import PositionSettings from "@pages/TokenSnipe/components/PositionSettings";
import cog from "@assets/icons/cog-white.svg";

interface PositionSettingsProps {
  position: PositionModel;
  updatePosition: (position: PositionModel) => void;
}

const PositionSettingsModal = observer(({ position, updatePosition }: PositionSettingsProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleClose = () => {
    if (isSaving) return;
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton={!isSaving}>
          <Modal.Title>Edit {position.token.name} settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PositionSettings position={position} onSave={updatePosition} onClose={handleClose} isSaving={setIsSaving} />
        </Modal.Body>
      </Modal>

      <div className="wd-35 ht-35 bg-semi-transparent-10 rounded-1 d-flex justify-content-center align-items-center cur-pointer" onClick={() => setShow(true)}>
        <img src={cog} alt="settings" width={16} height={16} />
      </div>
    </>
  );
});

export default PositionSettingsModal;
