interface SnipeRowProps {
  children: React.ReactNode;
  className?: string;
}

export default function SnipeColumn({children, className = ' tx-13 tx-semibold'}: SnipeRowProps) {
  return (
    <div className={`wd-50p flex-1 card bg-semi-transparent-10 py-1 flex-row align-items-center justify-content-between ${className}`}>
      {children}
    </div>
  )
}
