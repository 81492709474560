import {useCallback} from "react";
import {toast} from "react-hot-toast";
import {getRandomInt} from "@helpers/numbers";

interface NotificationProps {
  type?: 'success' | 'danger';
  duration?: number;
}

const useNotification = () => {
  const handleRemove = (id: string, text: string, type: string) => {
    toast.custom(
      <div className={`notification notification-out px-3 py-2 rounded-5 tx-13 tx-white alert-box-shadow bg-${type}`}>
        {text}
      </div>
      , {id});

    setTimeout(() => {
      toast.remove(id);
    }, 300);
  };

  return useCallback((text: string, {type = 'success', duration = 5000}: NotificationProps = {type: 'success', duration: 5000}) => {
    const id = new Date().getTime() + getRandomInt(100) + '';
    let timeout: NodeJS.Timeout;

    if (duration) {
      timeout = setTimeout(() => {
        handleRemove(id, text, type);
      }, duration);
    }

    toast.custom(
      <div
        className={`notification px-3 py-2 rounded-5 tx-13 tx-white alert-box-shadow bg-${type}`}
        onClick={() => {
          if (timeout) {
            clearTimeout(timeout);
          }
          handleRemove(id, text, type);
        }}
      >
        {text}
      </div>
    , {id});
  }, []);
};

export default useNotification;
