import React from "react";

const SignalInitialLiquidity = ({signal}) => {
  return (
    <>
      <div className="d-block liquidity mt-2">
        <div className="row gx-2">
          <div className="col-6">
            <div className="card bg-semi-transparent-10 px-2 py-1">
              <div className="tx-12 tx-muted">
                Initial Liq.
              </div>
              {signal.liquidity.initialAmount} {signal.liquidity.quoteSymbol}
            </div>
          </div>
          <div className="col-6">
            <div className="card bg-semi-transparent-10 px-2 py-1">
              <div className="tx-12 tx-muted">
                Current Liq.
              </div>
              {signal.liquidity.currentAmount} {signal.liquidity.quoteSymbol}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalInitialLiquidity;

