import React, {useEffect, useRef, useState} from "react";
import Preloader from "@components/common/Preloader";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../constants";
import {useStores} from "@hooks/useStores";
import useResponseHandler from "@hooks/useResponseHandler";
import PinInput from "@components/common/PinInput";
import {observer} from "mobx-react-lite";

interface CreateWalletProps {
  isImport?: boolean;
}

const CreateWallet = observer(({isImport = false}: CreateWalletProps) => {
  const { accountStore } = useStores();
  const { profile } = accountStore;
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const inputRefSecond = useRef(null);
  const [pin, setPin] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const handleResponse = useResponseHandler();

  const handleSubmit = () => {
    if (isImport) {
      navigate(PageRoutes.IMPORT_WALLET, {replace: true, state: {pin}});
      return;
    }

    accountStore
      .register(pin)
      .then(() => {
        accountStore.loadUser().then(() => {
          navigate(PageRoutes.WALLET_CREATED, {replace: true});
        });
      })
      .catch((e) => {
        setPin('');
        setConfirmPin('');
        setIsInvalid(true);
        handleResponse(e.response);
        setError('');
      });
  }

  const handleComplete = (value: string) => {
    if (!pin) {
      setPin(value);
      // clearPin();
    } else {
      if (pin !== value) {
        // clearPin();
        setIsInvalid(true);
        setPin('');
        setError('PIN codes do not match');
      } else {
        setConfirmPin(value);
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (!isImport && confirmPin && pin === confirmPin && profile) {
      navigate(PageRoutes.WALLET_CREATED, {replace: true});
    }
  }, [pin, confirmPin, profile, isImport]);

  useEffect(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current?.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (inputRefSecond.current) {
      // @ts-ignore
      inputRefSecond.current?.focus();
    }
  }, [inputRefSecond]);

  if (confirmPin && pin === confirmPin) {
    return (
      <div className="tx-center full-page wd-100p d-flex flex-column justify-content-center align-items-center">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text="" />
        <div className="tx-muted mt-3">Great! We are creating your wallet<br />«Alpha Wallet»</div>
      </div>
    )
  }

  return (
    <div className="tx-center full-page d-flex flex-column">
      <h1 className="tx-28 mt-5">
        {!pin ? 'Create your PIN' : 'Repeat the PIN code'}
      </h1>

      <div className="tx-muted my-3">
        {!pin ? 'Protect your wallet with a PIN code' : 'Make sure you remember the PIN code'}
      </div>

      {isInvalid && !!error && (
        <div className="tx-bold tx-danger mb-2">{error}</div>
      )}

      <div className={`flex-1 ${isInvalid ? 'invalid-pin' : ''}`}>
        {!pin && (
          <PinInput
            length={4}
            onChange={() => setIsInvalid(false)}
            onComplete={handleComplete}
            isInvalid={isInvalid}
          />
        )}
        {!!pin && (
          <PinInput
            length={4}
            onChange={() => setIsInvalid(false)}
            onComplete={handleComplete}
            isInvalid={isInvalid}
          />
        )}
      </div>
    </div>
  )
});

export default CreateWallet;
