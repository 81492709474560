import React from "react";
import {formatNumber} from "@helpers/numbers";
import {isNumber} from "@helpers/bignumber";

interface LabelValueCardProps {
  label: string;
  value: number | string;
  unit?: string;
  className?: string;
  valueClassName?: string;
  labelClassName?: string;
  isScore?: boolean;
}

const LabelValueCard = ({ label, value, unit, className = 'bg-semi-transparent-10 p-2', labelClassName = 'tx-muted', valueClassName = ''}: LabelValueCardProps) => {
  const formattedValue = isNumber(value) ? formatNumber(value as number, unit) : value;

  return (
    <div className={`wd-100p card d-flex flex-column align-items-center justify-content-center tx-center tx-12 ${className}`}>
      <div className={labelClassName}>{label}</div>
      <div className={valueClassName}>{formattedValue}</div>
    </div>
  );
};

export default LabelValueCard;
