import React from "react"
import clockGreyIcon from "@assets/whale-signals/clock-grey.svg";
import infoIcon from "@assets/whale-signals/info.svg";
import timeUpdGreyIcon from "@assets/whale-signals/timeupd-grey.svg";
import {msToString} from "@helpers/formatters";

const SignalTime = ({signal, signalSpentTime}) => {
  return (
    <>
      <div className="d-block mt-3">
        <div className="tx-17 tx-semibold">Time</div>

        <div className="row gx-2 gx-sm-0 gy-2 gy-sm-2 g-sm-2">
          <div className="col-6 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 d-flex flex-row align-items-center">
              <img src={clockGreyIcon} alt="" width="16" className="align-text-top"/>
              <span className="ps-1 pe-1">
                                {signalSpentTime[signal.liquidity.pairAddress] && msToString(signalSpentTime[signal.liquidity.pairAddress].firstWhaleAt)}
                            </span>
              <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                    data-bs-html="true"
                    data-bs-title="Duration since the token's initial<br> appearance on this page">
                                <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                            </span>
            </div>
          </div>
          <div className="col-6 col-sm-12">
            <div className="card bg-semi-transparent-10 px-2 py-2 d-flex flex-row align-items-center">
              <img src={timeUpdGreyIcon} alt="" width="16" className="align-text-top pr-1"/>
              <span className="ps-1 pe-1">
                                {signalSpentTime[signal.liquidity.pairAddress] && msToString(signalSpentTime[signal.liquidity.pairAddress].lastUpdateAt)}
                            </span>
              <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                    data-bs-html="true"
                    data-bs-title="Duration since the last anomaly<br> was detected in the token">
                                <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                            </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignalTime;

