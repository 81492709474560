import React, {useEffect, useMemo, useRef} from "react";
import {useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {greaterThan} from "@helpers/bignumber";
import {useStores} from "@hooks/useStores";
import {useCloudStorage, useWebApp} from "@vkruglikov/react-telegram-web-app";
import BottomNav from "@components/BottomNav";
import {
  autoTradeRoutes,
  PageRoutes,
  portfolioRoutes,
  profileRoutes,
  researchRoutes,
  tokenTradeRoutes
} from "../constants";

interface MainLayoutProps {
  children: React.ReactNode;
}

const fullPageRoutes = [
  '/swap',
  '/',
];

const bottomNavRoutesExact = [
  ...autoTradeRoutes,
  ...portfolioRoutes,
  ...researchRoutes,
  ...profileRoutes,
  ...tokenTradeRoutes,
];

const MainLayout = observer((props: MainLayoutProps) => {
  const {children} = props;
  const location = useLocation();
  const { settingsStore } = useStores();
  const { initialized, triggerUpdate, state } = settingsStore;
  const {setItem, getItems, getKeys} = useCloudStorage();
  const lastTriggerUpdateRef = useRef(0);
  const WebApp = useWebApp();

  useEffect(() => {
    if (WebApp && initialized && lastTriggerUpdateRef.current < triggerUpdate) {
      lastTriggerUpdateRef.current = triggerUpdate;
      if (WebApp.hasOwnProperty('CloudStorage') && greaterThan(WebApp.version, '6.0')) {
        state.forEach(({value, key}, index) => {
          setItem(key, JSON.stringify(value)).then(() => {
            if (index === state.length - 1) {
              settingsStore.setSaved();
            }
          });
        });
      } else {
        state.forEach(({value, key}) => {
          localStorage.setItem(key, JSON.stringify(value));
        });
        settingsStore.setSaved();
      }
    }
  }, [setItem, WebApp, state, initialized, triggerUpdate, lastTriggerUpdateRef, settingsStore]);

  useEffect(() => {
    if (WebApp && !initialized) {
      if (WebApp.hasOwnProperty('CloudStorage') && greaterThan(WebApp.version, '6.0')) {
        getKeys().then((keys) => {
          getItems(keys).then((items) => {
            settingsStore.restoreSettings(keys, Object.values(items));
          });
        });
      } else {
        const keys = Object.keys(localStorage);
        const values: string[] = [];
        keys.forEach((key) => {
          values.push(localStorage.getItem(key) || '');
        });
        settingsStore.restoreSettings(keys, values);
      }
    }
  }, [WebApp, initialized, getKeys, getItems, settingsStore]);

  const className = useMemo(() => {
    return fullPageRoutes.some((route) => location.pathname === route) ? '' : 'container';
  }, [location.pathname]);

  const withBottomNav = useMemo(() => {
    return bottomNavRoutesExact.some((route) => location.pathname === route);
  }, [location.pathname]);

  return (
    <div id="main-layout" className={className}>
      {children}
      {withBottomNav && <BottomNav />}
    </div>
  );
});

export default MainLayout;
