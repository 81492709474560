import React, {useCallback, useEffect, useState} from "react";
import {AutoTradeSource, IAutoTradeSettings, StrategyModel} from "../../../types";
import {observer} from "mobx-react-lite";
import SnipeRow from "@pages/Profile/SnipeRow";
import SnipeColumn from "@pages/Profile/SnipeColumn";
import Toggle from "react-toggle";
import edit from "@assets/icons/edit.svg";
import chevron from "@assets/icons/chevron.svg";
import ether from "@assets/images/chains/ether.svg";
import {numberRegex, PageRoutes} from "../../../constants";
import IconWithLoading from "@components/common/IconWithLoading";
import {useStores} from "@hooks/useStores";
import Preloader from "@components/common/Preloader";
import {useShowPopup} from "@vkruglikov/react-telegram-web-app";
import useNotification from "@hooks/useNotification";
import HistoryLogo from "@pages/Wallet/components/HistoryLogo";
import {useLocation, useNavigate} from "react-router-dom";
import SettingsInput from "@components/common/SettingsInput";
import whale from "@assets/images/auto-trade/whale.svg";
import Badge from "@components/common/Badge";
import telegram from "@assets/images/auto-trade/telegram.svg";

interface StrategySettingsProps {
  data?: StrategyModel;
  onSave?: (position: StrategyModel) => void;
  onClose?: () => void;
  isSaving?: (value: boolean) => void;
}

const StrategySettings = observer((props: StrategySettingsProps) => {
  const { accountStore, settingsStore } = useStores();
  const {amounts} = settingsStore;
  const navigate = useNavigate();
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  // const [position, setPosition] = useState<StrategyModel>();
  const [settings, setSettings] = useState<IAutoTradeSettings | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
  const [source, setSource] = useState<AutoTradeSource | null>(null);
  // const params = useParams();
  const notify = useNotification();
  const showPopup = useShowPopup();
  const {state} = useLocation();
  const [name, setName] = useState<string>(state?.strategy?.name || 'Strategy 1');

  // const mainData = useMemo(() => {
  //   if (!position) return null;
  //
  //   const {
  //     createdAt,
  //     updatedAt,
  //     id,
  //     userId,
  //     label,
  //     ...rest
  //   } = position.settings;
  //
  //   return rest;
  // }, [position]);

  const isInvalid = useCallback((key: string, isReq = false) => {
    if (!settings) {
      return false;
    }
    // @ts-ignore
    const value = settings[key];
    if (isReq && (value === undefined || value === null)) {
      return true;
    }
    if (!isReq && (value === undefined || value === null)) {
      return false;
    }
    if (key.toLowerCase().includes('delta') && value && value < 0) {
      return true;
    }
    return isNaN(value) || !numberRegex.test(value.toString());
  }, [settings]);

  // useEffect(() => {
  //   if (!settings && mainData) {
  //     setSettings(JSON.parse(JSON.stringify(mainData)));
  //   }
  // }, [settings, mainData]);

  const handleClear = () => {
    setSettings(JSON.parse(JSON.stringify(state?.strategy?.positionSettings || accountStore.snipeSettings)));
    setAmount(state?.strategy?.positionSettings.ethAmount || '');
  };

  useEffect(() => {
    if (!settings && (state?.strategy || accountStore.snipeSettings)) {
      handleClear();
    }
  }, [state, settings, accountStore.snipeSettings]);

  useEffect(() => {
    if (settings && settings.trailingStopLossEnabled && !settings.stopLossEnabled) {
      setSettings({...settings, stopLossEnabled: true});
    }
  }, [settings]);

  useEffect(() => {
    if (props.isSaving) {
      props.isSaving(isSaving);
    }
  }, [props, isSaving]);

  const handleSubmit = () => {
    if (!settings) return;

    const checks = [
      {check: isInvalid('stopLossPercent', settings.stopLossEnabled), label: 'Stop Loss'},
      {check: isInvalid('takeProfitPercent', settings.takeProfitEnabled), label: 'Take Profit'},
      {check: isInvalid('stopLossSellPercent', settings.stopLossEnabled), label: 'Stop Loss Amount'},
      {check: isInvalid('takeProfitSellPercent', settings.takeProfitEnabled), label: 'Take Profit Amount'},
      {check: isInvalid('sellSlippage', true), label: 'Sell Slippage'},
      {check: isInvalid('buySlippage', true), label: 'Buy Slippage'},
      {check: isInvalid('sellGasDelta'), label: 'Sell Priority Fee'},
      {check: isInvalid('buyGasDelta'), label: 'Buy Priority Fee'},
      {check: isInvalid('approveGasDelta'), label: 'Approve Priority Fee'},
    ];

    if (checks.some(({check}) => check)) {
      notify(checks.find((c) => c.check)?.label + ' is invalid or empty', {type: 'danger'});
      return;
    }

    navigate(PageRoutes.AUTO_TRADE_FILTERS, {state: {settings, amount, name, strategy: state?.strategy}})
  };

  // useEffect(() => {
  //   if (position) return;
  //   if (props.data) {
  //     setPosition(props.data);
  //   } else if (params.hasOwnProperty('id') && params.id) {
  //     accountStore.getPosition(params.id || '')
  //       .then((response) => {
  //         if (response) {
  //           setPosition(response);
  //         }
  //       });
  //   }
  // }, [position, props, params]);

  if (!state?.strategy && !source) {
    return (
      <div className="pt-3 pb-5">
        <div className="tx-28 tx-semibold mb-3">
          Auto Trade
        </div>

        <div>
          <div className="d-flex flex-row gap-3 mb-3">
            <div
              className="card card-button wd-50p active"
              onClick={() => setSource(AutoTradeSource.ETH)}
            >
              <img src={ether} alt="ether" width={32} height={32} className="mr-2" />
              <div>
                All Ethereum<br />
                New pairs
              </div>
            </div>
            <div className="card wd-50p card-button disabled">
              <img src={whale} alt="whale" width={32} height={32} className="mr-2" />
              <div className="d-flex align-items-center justify-content-between wd-100p">
                <div>
                  Whale<br />
                  signals
                </div>
                <Badge label="Soon" />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row gap-3">
            <div className="card wd-50p card-button disabled">
              <img src={telegram} alt="telegram" width={32} height={32} className="mr-2" />
              <div className="d-flex align-items-center justify-content-between wd-100p">
                <div>
                  Snipe<br />
                  scope
                </div>
                <Badge label="Soon" />
              </div>
            </div>
            <div className="card wd-50p card-button disabled">
              <img src={telegram} alt="telegram" width={32} height={32} className="mr-2" />
              <div className="d-flex align-items-center justify-content-between wd-100p">
                <div>
                  Block<br />
                  X-Ray
                </div>
                <Badge label="Soon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!settings) {
    return (
      <div className="tx-center pt-5">
        <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block mt-2" iconClass="d-block" text="Loading settings..." />
      </div>
    )
  }

  return (
    <div className="pt-3 pb-5">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div className="tx-28 tx-semibold">
          Strategy settings
        </div>
        <button
          onClick={() => {
            showPopup({
              title: 'Clear settings?',
              message: 'Do you really want to clear the settings?',
              buttons: [
                {
                  text: 'Cancel',
                  id: 'close',
                },
                {
                  text: 'Clear',
                  type: 'destructive',
                  id: 'clear',
                },
              ],
            }).then((result) => {
              if (result === 'clear') {
                handleClear();
              }
            });
          }}
          className="btn btn-link text-decoration-none tx-semibold tx-primary tx-13 tx-right px-0 py-0"
        >
          Clear settings
        </button>
      </div>

      <div className="card mb-4">
        <div className="d-flex flex-row justify-content-between align-items-center wd-100p pb-3 border-bottom border-semi-transparent">
          <HistoryLogo mainLogo={ether} size={45} />

          <div className="wd-100p ms-2">
            {!isNameEditing && (
              <div className="d-flex align-items-center justify-content-start cur-pointer" onClick={() => setIsNameEditing(true)}>
                <div className="tx-17 tx-semibold me-2">{name}</div>
                <img src={edit} alt="edit" width={16} height={16} />
              </div>
            )}
            {isNameEditing && (
              <form onSubmit={() => setIsNameEditing(false)}>
                <input
                  type="text"
                  className="form-control appearance-none px-0 py-0 tx-17 tx-semibold bg-transparent tx-white border-0"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoFocus
                />
              </form>
            )}
            <div className="tx-12 tx-muted">All New ETH Pairs</div>
          </div>
        </div>

        <div className="mt-3 tx-13 mb-1">
          Amount
        </div>
        <input
          type="number"
          placeholder="0.1 ETH"
          className="form-control wd-100p appearance-none"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <div className="d-flex justify-content-between align-items-center wd-100p gap-2 mt-2 tx-13 tx-semibold">
          {amounts.map((a) => (
            <button
              className="btn btn-secondary bg-semi-transparent-10 p-2 justify-content-center align-items-center wd-100p"
              key={`strategy-settings-amount-${a}`}
              onClick={() => setAmount(a)}
            >
              {a} ETH
            </button>
          ))}
        </div>
      </div>

      <div>
        <div>Take Profit & Stop Loss</div>
        <div className="card mt-1 mb-2">
          <SnipeRow>
            <SnipeColumn>
              <div>Take Profit</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.takeProfitEnabled}
                onChange={(e) => setSettings({...settings, takeProfitEnabled: e.target.checked})}
              />
            </SnipeColumn>
            <SnipeColumn>
              <div>Stop Loss</div>
              <Toggle
                icons={false}
                className="styled-toggle my-2"
                checked={settings.stopLossEnabled}
                onChange={(e) => setSettings({...settings, stopLossEnabled: e.target.checked, trailingStopLossEnabled: false})}
              />
            </SnipeColumn>
          </SnipeRow>

          {settings.takeProfitEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip="The percentage of profit at which a sell will be executed"
                isRequired
              >
                Take Profit
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="takeProfitSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip="The percentage of the total token amount to be sold when the specified profit level is reached"
              >
                Take Profit Amount
              </SettingsInput>
            </SnipeRow>
          )}

          {settings.stopLossEnabled && (
            <SnipeRow className="mt-2">
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip="The percentage of loss at which a sell will be executed"
                isRequired
              >
                Stop Loss
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="stopLossSellPercent"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip="The percentage of the total token amount to be sold when the specified loss level is reached"
              >
                Stop Loss Amount
              </SettingsInput>
            </SnipeRow>
          )}
        </div>

        <div className={`mt-4 mb-2 ${isDetailsOpen ? 'd-block' : 'd-none'}`}>
          <div>General settings</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="maxGasPrice"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip="The maximum gas price you're willing to pay for a transaction"
              >
                Max Gas Price
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="approveGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
              >
                Approve Priority Fee
              </SettingsInput>
            </SnipeRow>

            <SnipeRow className="mt-3">
              <SnipeColumn>
                <div>MEV Guard</div>
                <Toggle
                    icons={false}
                    className="styled-toggle my-2"
                    checked={settings.mevGuardEnabled}
                    onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>Fail Guard</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.failGuard}
                  onChange={(e) => setSettings({...settings, failGuard: e.target.checked})}
                />
              </SnipeColumn>
            </SnipeRow>

            {/*<SnipeRow className="mt-3">*/}
            {/*  <SnipeColumn>*/}
            {/*    <div className="tx-gray-300">Anti Rug</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      disabled*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*  <SnipeColumn>*/}
            {/*    <div>MEV Guard</div>*/}
            {/*    <Toggle*/}
            {/*      icons={false}*/}
            {/*      className="styled-toggle my-2"*/}
            {/*      checked={settings.mevGuardEnabled}*/}
            {/*      onChange={(e) => setSettings({...settings, mevGuardEnabled: e.target.checked})}*/}
            {/*    />*/}
            {/*  </SnipeColumn>*/}
            {/*</SnipeRow>*/}

            <SnipeRow className="mt-3">
              <SnipeColumn className="tx-12 tx-semibold">
                <div>Auto Approve</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.autoApprove}
                  onChange={(e) => setSettings({...settings, autoApprove: e.target.checked})}
                />
              </SnipeColumn>
              <SnipeColumn>
                <div>Trailing Loss</div>
                <Toggle
                  icons={false}
                  className="styled-toggle my-2"
                  checked={settings.trailingStopLossEnabled}
                  onChange={(e) => setSettings({...settings, trailingStopLossEnabled: e.target.checked})}
                />
              </SnipeColumn>
            </SnipeRow>
          </div>

          <div className="mt-4">Buy settings</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buySlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip="The percentage of price change at which your purchase will still be executed"
                isRequired
              >
                Slippage
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="buyGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
              >
                Priority Fee
              </SettingsInput>
            </SnipeRow>
          </div>

          <div className="mt-4">Sell settings</div>
          <div className="card mt-1">
            <SnipeRow>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellSlippage"
                isInvalidHandler={isInvalid}
                placeholder="1%"
                unit="%"
                tooltip="The percentage of price change at which your sale will still be executed"
                isRequired
              >
                Slippage
              </SettingsInput>
              <SettingsInput
                data={settings}
                setHandler={setSettings}
                dataKey="sellGasDelta"
                isInvalidHandler={isInvalid}
                placeholder="GWEI"
                unit="GWEI"
                tooltip="Extra 'tip' to have your transaction completed faster. The higher the priority fee, the higher the chance of getting your transaction processed sooner."
              >
                Priority Fee
              </SettingsInput>
            </SnipeRow>
          </div>
        </div>

        <div className="pb-4 mb-2 mt-4 tx-13 tx-center border-bottom border-semi-transparent cur-pointer with-hover" onClick={() => setIsDetailsOpen((prev) => !prev)}>
          <span>More settings</span>
          <img src={chevron} alt="toggle details" width={16} height={16} className={`ms-1 will-rotate ${isDetailsOpen ? 'rotate-180' : ''}`} />
        </div>
      </div>

      <button
        className="btn btn-light wd-100p mt-3"
        onClick={handleSubmit}
        disabled={isSaving}
      >
        <IconWithLoading isLoading={isSaving} />
        Continue
      </button>

      <button
        className="btn btn-transparent wd-100p mt-2 mb-3"
        onClick={() => {
          showPopup({
            title: 'Close auto trade settings?',
            message: 'If you close the trade settings, the changes will not be saved',
            buttons: [
              {
                text: 'Close',
                id: 'close',
              },
              {
                text: 'Cancel',
                type: 'destructive',
                id: 'cancel',
              },
            ],
          }).then((result) => {
            if (result === 'cancel') {
              navigate(-1);
            }
          });
        }}
        disabled={isSaving}
      >
        Cancel
      </button>
    </div>
  );
});

export default StrategySettings;
