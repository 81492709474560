import {chainLogo, chainName} from "@helpers/chains";
import {OperationType} from "../../../types/transactions.types";

interface TokenLogoProps {
  type?: OperationType;
  size?: number;
  className?: string;
  containerClassName?: string;
  symbolIn?: string;
  symbolOut?: string;
  chain?: string;
  mainLogo?: string;
  secondLogo?: string;
  bg?: string;
  onClick?: () => void;
  isRobot?: boolean;
  index?: number | string;
}

export default function HistoryLogo(props: TokenLogoProps) {
  const {
    symbolIn,
    symbolOut,
    size = 45,
    containerClassName = '',
    className = '',
    chain,
    type = OperationType.Approve,
    mainLogo,
    secondLogo,
    bg = 'bg-gray-600',
    onClick,
    isRobot,
    index,
  } = props;

  const isTradeOrSend = type === OperationType.Trade || type === OperationType.Send;
  const isApprove = type === OperationType.Approve;
  const chainLogoImage = chainLogo(chain || '');
  const network = chainName(chain || '');

  return (
    <div className={`pos-relative ${containerClassName}`} onClick={onClick}>
      <div className={`ms-0 wd-${size} mn-wd-${size} mx-wd-${size} ht-${size} token-logo d-flex ${className} ${type === OperationType.Trade && 'token-logo--double'}`}>
        {type === OperationType.Trade && !secondLogo && !!symbolOut && (
          <div className={`${bg} rounded-circle wd-${size} ht-${size} px-1 d-flex justify-content-center align-items-center tx-12 tx-center pos-relative overflow-hidden token-logo--main-text`}>
            <div className="text-truncate">{symbolOut}</div>
          </div>
        )}
        {isTradeOrSend && !!secondLogo && (
          <img src={secondLogo} alt={symbolIn || symbolOut} />
        )}
        {!!mainLogo && (
          <img src={mainLogo} alt={symbolOut || symbolIn || network} />
        )}
        {isApprove && !mainLogo && !symbolIn && (
          <img src={chainLogoImage} alt={network} />
        )}
        {!mainLogo && type !== OperationType.Send && (symbolIn || symbolOut) && (
          <div className={`${bg} rounded-circle wd-${size} ht-${size} px-1 d-flex lh-1 justify-content-center align-items-center tx-12 tx-center pos-relative overflow-hidden token-logo--second-text`}>
            <div className="text-truncate">{symbolIn || symbolOut}</div>
          </div>
        )}
      </div>
      {((isApprove && !mainLogo && chain) || (!isApprove && chain)) && (
        <div className={`chain-label`}>
          <img src={chainLogoImage} alt={chain} className="wd-20 ht-20" />
        </div>
      )}
      {!!index && (
        <div className="left-label">
          {index}
        </div>
      )}
      {isRobot && (
        <div className="chain-label is-robot">
          🤖
        </div>
      )}
    </div>
  );
}
