import React, {useEffect, useState} from "react";
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min';
import SignalTime from "./SignalTime";
import SignalName from "./SignalName";
import SignalChange from "./SignalChange";
import SignalAnomaly from "./SignalAnomaly";
import SignalMetrics from "./SignalMetrics";
import SignalSecurity from "./SignalSecurity";
import SignalInitialLiquidity from "./SignalInitialLiquidity";
import Errors from "./Errors";
import {ENV} from "../../../constants";

function WhaleSignalsTable({bot}) {
  const [status, setStatus] = useState('loading')
  const [whaleSignals, setWhaleSignals] = useState([])
  const [signalSpentTime, setSignalSpentTime] = useState({})


  const refreshWhaleSignals = () => {
    fetch(`${ENV.WHALE_SIGNALS_URL}/api/v1/signals`)
      .then(resp => resp.json())
      .then(signals => {
        setWhaleSignals(signals)
        setStatus('ok')
      })
      .catch(_ => setStatus('error'));
  }

  useEffect(() => {
    refreshWhaleSignals()
    const intervalId = setInterval(refreshWhaleSignals, 5000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    const refreshSpentTime = () => {
      if (!whaleSignals || !whaleSignals.length) {
        setSignalSpentTime({})
        return
      }

      const curTime = new Date().getTime()
      const newSignalSpentTime = whaleSignals.reduce((result, signal) => {
        result[signal.liquidity.pairAddress] = {
          firstWhaleAt: (curTime - new Date(signal.firstWhaleAt).getTime()),
          lastUpdateAt: (curTime - new Date(signal.updatedAt).getTime()),
        }
        return result
      }, {})

      setSignalSpentTime(newSignalSpentTime)
    }
    refreshSpentTime()
    const intervalId = setInterval(refreshSpentTime, 900)
    return () => clearInterval(intervalId)
  }, [whaleSignals])

  useEffect(() => {
    const tooltipTriggers = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltips = tooltipTriggers.map(el => {
      const tooltip = new Tooltip(el);
      if (el.matches(':hover')) {
        tooltip.show();
      }
      return tooltip;
    });

    return () => {
      tooltips.forEach(tooltip => tooltip.dispose());
    };
  }, [whaleSignals]);

  return (
    <>
      {status === 'ok' && !!whaleSignals.length && (
        <>
          <div className="mt-4 d-block">
            <div className="tx-28 tx-semibold mb-3">
              Whale Signals {whaleSignals.length ? `(${whaleSignals.length})` : ''}
            </div>

            <div className="g-5">
              {whaleSignals.sort((a,b) => b.score - a.score).map((signal, key) =>
                <div className="wd-100p mb-4" key={key}>
                  <div className="card">
                    <SignalName signal={signal} bot={bot}/>

                    <SignalChange signal={signal}/>

                    <SignalMetrics signal={signal}/>

                    <SignalInitialLiquidity signal={signal}/>

                    <SignalTime signal={signal} signalSpentTime={signalSpentTime}/>

                    <SignalAnomaly signal={signal}/>

                    <SignalSecurity signal={signal}/>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="content d-flex d-lg-none">
        <Errors status={status} whaleSignalsLength={whaleSignals.length}/>
      </div>
    </>
  );
}

export default WhaleSignalsTable;
