import {observer} from "mobx-react-lite";
import searchIcon from "@assets/icons/search.svg";
import whale from "@assets/icons/whale.svg";
import {useNavigate} from "react-router-dom";
import {ethRegex} from "../../constants";
import React, {useEffect, useMemo, useState} from "react";
import useDebounce from "@hooks/useDebounce";
import {useStores} from "@hooks/useStores";
import FormattedNumber from "@components/common/FormattedNumber";
import BadgeIcon from "@components/common/BadgeIcon";
import Preloader from "@components/common/Preloader";
import cross from "@assets/icons/cross.svg";
import {HotToken, PositionModel, PositionStatus} from "../../types";
import useResponseHandler from "@hooks/useResponseHandler";
import BigNumber from "bignumber.js";
import useNotification from "@hooks/useNotification";
import ComingSoon from "@pages/Wallet/ComingSoon";
import ResearchItem from "@pages/Research/components/ResearchItem";

const Research = observer(() => {
  const { accountStore } = useStores();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const debounceSearch = useDebounce(search, 500);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [tokens, setTokens] = useState<HotToken[]>([]);
  // const [positions, setPositions] = useState<PositionModel[]>([]);
  const [isTokensLoaded, setIsTokensLoaded] = useState<boolean>(false);
  const handleResponse = useResponseHandler();

  const isAddressValid = useMemo(() => {
    return ethRegex.test(search);
  }, [search]);

  // useEffect(() => {
  //   if (!isPositionsLoaded) {
  //     accountStore.getSnipePositions()
  //       .then((response) => {
  //         if (response) {
  //           setPositions(response);
  //           setIsPositionsLoaded(true);
  //         } else {
  //           handleResponse(response);
  //         }
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //         handleResponse(e.response);
  //         setIsPositionsLoaded(true);
  //       })
  //   }
  // }, [isPositionsLoaded]);

  useEffect(() => {
    if (isTokensLoaded) return;

    accountStore.getHotTokens().then((response) => {
      setIsTokensLoaded(true);
      if (Array.isArray(response)) {
        setTokens(response.slice(0, 20));
      } else {
        handleResponse(response);
      }
    }).catch((e) => {
      handleResponse(e.response);
      setIsTokensLoaded(true);
    });
  }, [isTokensLoaded]);

  useEffect(() => {
    if (search) {
      setIsSearching(true);
    }
    setIsInvalid(false);
  }, [search]);

  useEffect(() => {
    if (debounceSearch && isAddressValid) {
      accountStore.getTokenDetails(debounceSearch)
        .then((response) => {
          setIsSearching(false);
          if (response && response.hasOwnProperty('pairAddress')) {
            navigate('/token-snipe/' + response.pairAddress);
          } else {
            setIsInvalid(true);
          }
        })
        .catch(() => {
          setIsInvalid(true);
          setIsSearching(false);
        });
    }
  }, [debounceSearch, isAddressValid]);

  useEffect(() => {
    if (search && search === debounceSearch && !isAddressValid) {
      setIsSearching(false);
      setIsInvalid(true);
    }
  }, [search, debounceSearch, isAddressValid]);

  return (
    <div className="pt-3 pb-5">
      <div className="tx-28 tx-semibold d-flex align-items-start">
        <span>Research</span>
        <span className="tx-13 ms-1">(24H)</span>
      </div>

      {!isTokensLoaded && (
        <div className="tx-center pt-5">
          <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block tx-semibold tx-28 mt-4" iconClass="d-block" text="Loading hot tokens..."/>
        </div>
      )}

      {/*<div className={`input-group mt-2 mb-3 rounded-2 ${isInvalid ? 'is-invalid' : ''}`}>*/}
      {/*  <div className="input-group-text py-0 ht-35 pe-1 ps-2 border-0">*/}
      {/*    <img src={searchIcon} alt="search" width={16} height={16} />*/}
      {/*  </div>*/}
      {/*  <input*/}
      {/*    type="text"*/}
      {/*    className={`form-control ht-35 ps-0 py-1 border-0 outline-none ${isInvalid ? 'tx-danger is-invalid' : 'tx-gray-400'}`}*/}
      {/*    value={search}*/}
      {/*    onChange={(e) => setSearch(e.target.value)}*/}
      {/*    placeholder="Token address"*/}
      {/*  />*/}
      {/*  {!!search && (*/}
      {/*    <div className="input-group-text py-0 ht-35 pe-2 ps-1 border-0">*/}
      {/*      <img src={cross} alt="Clear" width={16} height={16} onClick={() => setSearch('')} />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*</div>*/}

      {search && isSearching && (
        <div className="tx-center pt-5">
          <Preloader inline iconSize={64} className="d-flex flex-column align-items-center" textClass="d-block tx-semibold tx-28 mt-4" iconClass="d-block" text="Searching token..." />
        </div>
      )}

      {search && !isSearching && (
        <div className="tx-center">
          <BadgeIcon badgeSize={85} className="tx-38 mb-3 mt-5">🔎</BadgeIcon>
          <h1 className="tx-28 mt-4">It's empty :(</h1>
          <div className="tx-muted my-3">Nothing was found for your request. Try specifying a query based on other data</div>
        </div>
      )}

      {!search && (
        <div>
          {/*<div className="d-flex align-items-center justify-content-between gap-2">*/}
          {/*  <button className="btn btn-secondary wd-100p tx-12 lh-2">*/}
          {/*    <img src={whale} alt="Whale Signals" width={24} className="mb-1" />*/}
          {/*    <div>Whale Signals</div>*/}
          {/*  </button>*/}
          {/*  <button className="btn btn-secondary wd-100p tx-12 lh-2">*/}
          {/*    <img src={whale} alt="Whale Signals" width={24} className="mb-1" />*/}
          {/*    <div>Whale Signals</div>*/}
          {/*  </button>*/}
          {/*  <button className="btn btn-secondary wd-100p tx-12 lh-2">*/}
          {/*    <img src={whale} alt="Whale Signals" width={24} className="mb-1" />*/}
          {/*    <div>Whale Signals</div>*/}
          {/*  </button>*/}
          {/*</div>*/}
          {tokens.map((item, index) => (
            <ResearchItem key={index} data={item} isLast={tokens.length -1 === index} index={index} />
          ))}
        </div>
      )}
    </div>
  );
});

export default Research;
